import React, { FunctionComponent } from 'react';

import { Container } from '@mui/material';
import { Breakpoint, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface Props {
  sx?: object;
  maxWidth?: false | Breakpoint | undefined;
  children?: React.ReactNode;
}

const ResponsiveContainer: FunctionComponent<Props> = ({ sx, maxWidth = 'lg', children }: Props) => {
  const theme = useTheme();
  const isXsViewPort = useMediaQuery(theme.breakpoints.only('xs'));
  return (
    <Container sx={sx} maxWidth={maxWidth} disableGutters={isXsViewPort}>
      {children}
    </Container>
  );
};

export default ResponsiveContainer;
