import { FunctionComponent } from 'react';

import { Checkbox, FormControlLabel, Typography } from '@mui/material';

interface Props {
  isChecked: boolean;
  setIsChecked: (val: boolean) => void;
  label: string;
  labelLeft?: boolean;
  small?: boolean;
  italic?: boolean;
  disabled?: boolean | undefined;
}

const CustomCheckboxContol: FunctionComponent<Props> = ({
  isChecked,
  setIsChecked,
  label,
  labelLeft,
  small,
  italic,
  disabled = false
}: Props) => {
  const checkbox = (
    <Checkbox
      size={small ? 'small' : 'medium'}
      checked={isChecked}
      onChange={() => setIsChecked(!isChecked)}
      inputProps={{ 'aria-label': 'controlled' }}
      disabled={disabled}
    />
  );
  const formattedLabel = (
    <Typography sx={{ fontStyle: italic ? 'italic' : 'normal' }} variant={small ? 'body2' : 'body1'}>
      {label}
    </Typography>
  );
  return <FormControlLabel control={checkbox} label={formattedLabel} labelPlacement={labelLeft ? 'start' : 'end'} />;
};

export default CustomCheckboxContol;
