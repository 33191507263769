import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import { Box, Card, Container, Grid, Typography } from '@mui/material';

import { WATCH_CHART_ID } from '../../assets/constants';
import { useConfirm } from '../../components/hooks/useConfirm';
import BarChartPanel from '../../components/panels/BarChartPanel';
import TrendLineDialog from '../../components/panels/TrendLinePanel/TrendLineDialog';
import TrendLineTable from '../../components/panels/TrendLinePanel/TrendLineTable';
import AccordionWrapper from '../../components/ui/AccordionWrapper';
import ResponsiveContainer from '../../components/ui/ResponsiveContainer';
import SecurityListTable from '../../components/ui/SecurityListTable';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getContractsByUnderlyingSymbolState } from '../../store/security-contract/selectors';
import { getSecurityContractsAsync } from '../../store/security-contract/service';
import { removeTrendLineAsync } from '../../store/trend-line/service';
import { setSelectedWatchItem, setShortListCollapsed, setTrendLinesCollapsed } from '../../store/user-settings/reducer';
import { Security, TrendLine } from '../../types/entities';

import QuickNoteAccordion from './QuickNoteAccordion';

const WatchlistPage: FunctionComponent = () => {
  const [editableTrendLine, setEditableTrendLine] = useState<TrendLine | undefined>();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const closeDialog = () => setShowEditDialog(false);

  const { favorites } = useAppSelector((gs) => gs.favoriteContractState);
  const { trendLines } = useAppSelector((gs) => gs.trendLineState);
  const { selectedWatchItem } = useAppSelector((gs) => gs.userSettingsState);
  const securityState = useAppSelector((gs) => gs.securityState);
  const { securities } = securityState;

  const filteredSecurities = useMemo(() => {
    return securities.filter((x) => x.shortList);
  }, [securities]);

  const dispatch = useAppDispatch();

  const [selectedSecurity, setSelectedSecurity] = useState<Security | undefined>();
  const secContractState = useAppSelector((gs) => gs.securityContractState);

  const { trendLinesCollapsed, shortListCollapsed } = useAppSelector((gs) => gs.userSettingsState);
  const toggleTrendLinesCollapsed = () => dispatch(setTrendLinesCollapsed(!trendLinesCollapsed));
  const toggleShortListCollapsed = () => dispatch(setShortListCollapsed(!shortListCollapsed));

  const setSelectedTradeLine = (trendLine: TrendLine | undefined) => {
    const watchItem = trendLine
      ? {
          symbol: trendLine.symbol,
          conId: trendLine.conId,
          localSymbol: trendLine.localSymbol,
          exchange: trendLine.exchange,
          secType: trendLine.secType
        }
      : undefined;
    dispatch(setSelectedWatchItem(watchItem));
  };

  const handleShortListMouseClick = (security: Security | undefined) => {
    if (!security) {
      dispatch(setSelectedWatchItem(undefined));
    }
    setSelectedSecurity(security);
  };

  useEffect(() => {
    if (selectedSecurity) {
      const { loaded } = getContractsByUnderlyingSymbolState(secContractState, selectedSecurity.symbol);
      if (!loaded) {
        dispatch(getSecurityContractsAsync(selectedSecurity));
      }
    }
  }, [selectedSecurity]);

  const contracts = useMemo(() => {
    if (!selectedSecurity) {
      return [];
    }
    return secContractState[selectedSecurity.symbol]?.contracts ?? [];
  }, [secContractState, selectedSecurity]);

  const getFavoriteContract = () => {
    const favContracts = favorites.map((x) => x.details);
    for (let i = 0; i < contracts.length; i++) {
      const curr = contracts[i].contract;
      const fav = favContracts.find((x) => x.contract.conId === curr.conId);
      if (fav) {
        return fav;
      }
    }
  };

  const contract = useMemo(() => {
    if (contracts.length === 1) {
      return contracts[0].contract;
    }
    const fav = getFavoriteContract();
    if (fav) {
      return fav.contract;
    }
  }, [contracts, favorites, getFavoriteContract]);

  useEffect(() => {
    if (contract) {
      dispatch(
        setSelectedWatchItem({
          symbol: contract.symbol,
          conId: contract.conId,
          localSymbol: contract.localSymbol,
          exchange: contract.exchange,
          secType: contract.secType
        })
      );
    }
  }, [contract]);

  const [confirm, ConfirmDialog] = useConfirm();
  const handleRemoveClick = async (trendLine: TrendLine) => {
    if (await confirm(`Are you sure you want to delete trade-line at $${trendLine.price.toFixed(2)}?`)) {
      dispatch(removeTrendLineAsync(trendLine));
    }
  };

  const handleEditBtnClick = (trendLine?: TrendLine | undefined) => {
    setEditableTrendLine(trendLine);
    setShowEditDialog(true);
  };

  return (
    <div>
      <Container maxWidth={false}>
        <Typography variant="h4" sx={{ my: 3 }}>
          Watchlist
        </Typography>
      </Container>
      <ResponsiveContainer maxWidth={false}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <QuickNoteAccordion />
              <AccordionWrapper title="Shortlist" collapsed={shortListCollapsed} onChange={toggleShortListCollapsed}>
                <SecurityListTable
                  securities={filteredSecurities}
                  onSecuritySelected={handleShortListMouseClick}
                  selectedSymbol={selectedSecurity?.symbol}
                />
              </AccordionWrapper>
              <AccordionWrapper title="Trend Lines" collapsed={trendLinesCollapsed} onChange={toggleTrendLinesCollapsed}>
                {trendLines.length > 0 ? (
                  <TrendLineTable
                    selectable
                    showSecurityName
                    trendLines={trendLines}
                    onSelected={setSelectedTradeLine}
                    onEdit={handleEditBtnClick}
                    onRemove={handleRemoveClick}
                  />
                ) : (
                  <Typography sx={{ px: 2, pb: 2 }} fontSize="smaller" component="div">
                    No trend-lines exists..
                  </Typography>
                )}
              </AccordionWrapper>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card>
              {selectedWatchItem && (
                <Box sx={{ pt: 1 }}>
                  <BarChartPanel
                    barChartId={WATCH_CHART_ID}
                    symbol={selectedWatchItem.symbol}
                    conId={selectedWatchItem.conId}
                    title={selectedWatchItem.localSymbol ?? selectedWatchItem.symbol}
                    exchange={selectedWatchItem.exchange}
                    secType={selectedWatchItem.secType}
                    showMoreBtn
                  />
                </Box>
              )}

              {!selectedWatchItem && (
                <Typography sx={{ p: 2 }} fontSize="smaller" component="div">
                  No security selected..
                </Typography>
              )}
            </Card>
          </Grid>
        </Grid>
      </ResponsiveContainer>

      {editableTrendLine && <TrendLineDialog trendLine={editableTrendLine} isOpen={showEditDialog} onClose={closeDialog} />}
      <ConfirmDialog />
    </div>
  );
};

export default WatchlistPage;
