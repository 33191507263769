import { FunctionComponent, useMemo } from 'react';

import { Box, Button, Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import racingFlagsIcon from '../../../../assets/svg/racing-flags.svg';
import { OpenOrderReport, Position, PositionPortfolio } from '../../../../types/entities';
import { getDurationString } from '../../../../utils/datetime-utils';
import { hasPositionEnded } from '../../../../utils/position-utils';
import MarketHoursIndicator from '../../../ui/MarketHoursIndicator';

import PositionOrderTable from './PositionOrderTable';
import PositionTradeTable from './PositionTradeTable';

interface Props {
  position: Position;
  portfolio: PositionPortfolio;
  orders: OpenOrderReport[];
}

const PositionExpandedPanel: FunctionComponent<Props> = ({ position, portfolio, orders }) => {
  const navigate = useNavigate();
  const { conId } = position.details.contract;

  const { created, completedDate } = position;
  const duration = useMemo(() => {
    const entryDate = new Date(created);
    const completedDt = completedDate ? new Date(completedDate) : new Date();
    return getDurationString(entryDate, completedDt);
  }, [created]);

  const gotoOrderBtnClick = () => {
    navigate(`/place-order/${conId}`);
  };

  const gotoAutomationBtnClick = () => {
    navigate(`/order-automation/${conId}`);
  };

  const isFinished = hasPositionEnded(position);

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {duration && (
            <Typography variant="caption" component="div">
              Position Duration: {duration}
            </Typography>
          )}
          <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
            {isFinished && <img src={racingFlagsIcon} width="24px" alt="Finished" />}
          </Box>
        </Box>
        <MarketHoursIndicator status={portfolio.marketTradingHours} />
      </Box>
      <Divider sx={{ my: 1 }} />
      <Box mb={2}>
        <Typography variant="caption">TRADES</Typography>
        <Box sx={{ my: 1 }}>{position.trades.length === 0 ? <small>No Trades..</small> : <PositionTradeTable position={position} />}</Box>
      </Box>
      <Typography variant="caption">EXISTING ORDERS</Typography>
      <Box sx={{ my: 1 }}>
        {orders.length === 0 ? <small>No Orders..</small> : <PositionOrderTable orders={orders} position={position} />}
      </Box>
      {!position.completedDate && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
          <Button sx={{ mt: 1 }} size="small" variant="outlined" onClick={gotoOrderBtnClick}>
            Place Order..
          </Button>
          <Button sx={{ mt: 1 }} size="small" variant="outlined" onClick={gotoAutomationBtnClick}>
            Automation..
          </Button>
        </Box>
      )}
    </>
  );
};

export default PositionExpandedPanel;
