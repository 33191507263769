import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PositionPortfolio } from '../../types/entities';

import { PositionPortfolioState, initialPositionPortfolioState } from './state';

const positionPortfolioSlice = createSlice({
  name: 'position-portfolio',
  initialState: initialPositionPortfolioState,
  reducers: {
    reset: () => initialPositionPortfolioState,
    loading: (state: PositionPortfolioState, action: PayloadAction<boolean>) => {
      state.error = undefined;
      state.loading = action.payload;
      state.loaded = !action.payload;
    },
    success: (state: PositionPortfolioState, action: PayloadAction<PositionPortfolio[]>) => {
      state.error = undefined;
      state.loading = false;
      state.loaded = true;
      state.portfolios = action.payload;
    },
    fail: (state: PositionPortfolioState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.loaded = true;
      state.portfolios = [];
    },
    add: (state: PositionPortfolioState, action: PayloadAction<{ conId: number }>) => {
      state.portfolios.push({
        conId: action.payload.conId,
        size: 0,
        marketPrice: 0,
        marketValue: 0,
        unrealizedPnl: 0,
        marketTradingHours: 0
      });
      state.loading = false;
      state.loaded = true;
    }
  }
});

const { actions, reducer } = positionPortfolioSlice;
export const { reset, loading, success, fail, add } = actions;
export default reducer;
