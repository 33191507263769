import { FunctionComponent } from 'react';

import { Box, Card, Container, Divider, Skeleton, Typography } from '@mui/material';

import NewsPanel from '../../components/panels/NewsPanel';
import PositionListPanel from '../../components/panels/PositionListPanel';
import ResponsiveContainer from '../../components/ui/ResponsiveContainer';
import { useAppSelector } from '../../store/hooks';

import { CashOverviewPanel } from './CashOverviewPanel';
import PriceAlertAccordion from './PriceAlertAccordion';
import SeasonalAccordion from './SeasonalAccordion';

const Dashboard: FunctionComponent = () => {
  const { initialLoaded } = useAppSelector((gs) => gs.dashboardState);
  const { positions } = useAppSelector((gs) => gs.positionState);
  const { portfolios } = useAppSelector((gs) => gs.positionPortfolioState);

  return (
    <>
      <Container sx={{ my: 3, display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
        <Typography variant="h4">Dashboard</Typography>
        <CashOverviewPanel positions={positions} portfolios={portfolios} />
      </Container>
      <ResponsiveContainer sx={{ mb: 3 }}>
        <PriceAlertAccordion />
      </ResponsiveContainer>

      <Container>
        <Typography variant="h5">Positions</Typography>
      </Container>

      <ResponsiveContainer>
        <Divider sx={{ mt: 0.5, mb: 2 }} />
        {!initialLoaded && (
          <Box display="flex" flexDirection="column" gap={2}>
            <Skeleton variant="rounded" animation="wave" height={60} />
            <Skeleton variant="rounded" animation="wave" height={60} />
            <Skeleton variant="rounded" animation="wave" height={60} />
          </Box>
        )}

        <PositionListPanel />
      </ResponsiveContainer>

      <Container sx={{ mt: 5 }}>
        <Typography variant="h5">Fundamentals</Typography>
      </Container>

      <ResponsiveContainer>
        <Divider sx={{ mt: 0.5, mb: 2 }} />
        <SeasonalAccordion />
        <Card sx={{ mt: 2 }}>
          <NewsPanel />
        </Card>
      </ResponsiveContainer>
    </>
  );
};

export default Dashboard;
