import React, { FunctionComponent } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

interface Props {
  title: string | JSX.Element;
  collapsed?: boolean;
  onChange?: (e: React.SyntheticEvent<Element, Event>, expanded: boolean) => void;
  sx?: object | undefined;
  innerPadding?: string | undefined;
  children?: React.ReactNode;
}

const AccordionWrapper: FunctionComponent<Props> = ({ title, collapsed, onChange, sx = {}, innerPadding = 0, children }) => {
  return (
    <Accordion disableGutters={true} expanded={!collapsed} onChange={onChange} sx={{ ...sx, backgroundColor: 'transparent' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {typeof title === 'string' ? <Typography variant="h6">{title}</Typography> : title}
      </AccordionSummary>
      <AccordionDetails sx={{ p: innerPadding }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionWrapper;
