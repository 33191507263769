import { FunctionComponent, useMemo } from 'react';

import { Box, Typography } from '@mui/material';

import { POSTION_ZEBRA_COLOR_EVEN, POSTION_ZEBRA_COLOR_ODD } from '../../assets/constants';
import { useAppSelector } from '../../store/hooks';
import { Position, PositionPortfolio } from '../../types/entities';
import GutterBox from '../ui/GutterBox';
import ReloadDashboardFab from '../ui/ReloadDashboardFab';
import Spinner from '../ui/Spinner';

import PositionPanel from './PositionPanel';

interface Props {
  symbol?: string | undefined;
}

const PositionListPanel: FunctionComponent<Props> = ({ symbol }: Props) => {
  const { positions, loading, loaded } = useAppSelector((gs) => gs.positionState);
  const { portfolios } = useAppSelector((gs) => gs.positionPortfolioState);
  const { orders } = useAppSelector((gs) => gs.openOrderState);

  const sortPositions = (a: Position, b: Position) => {
    if (!a || !b) {
      return 0;
    }
    return a.sortOrder < b.sortOrder ? 1 : -1;
  };

  const securityPositions = useMemo(() => {
    const filteredPos = symbol ? positions.filter((x) => x.details.contract.symbol === symbol) : positions;
    const secondFilterPos = symbol ? filteredPos : filteredPos.filter((x) => !x.isBacktest);
    return secondFilterPos
      .filter((x) => !x.completed)
      .sort(sortPositions)
      .map((pos, index) => {
        const { conId } = pos.details.contract;
        const portfolio = portfolios.find((x) => x.conId === conId) as PositionPortfolio;
        const posOrders = orders.filter((x) => x.conId === conId);
        return (
          <PositionPanel
            key={pos.id}
            position={pos}
            portfolio={portfolio}
            orders={posOrders}
            bg={index % 2 === 0 ? POSTION_ZEBRA_COLOR_EVEN : POSTION_ZEBRA_COLOR_ODD}
          />
        );
      });
  }, [positions, portfolios, symbol]);

  const noPositionsPanel =
    loaded && securityPositions.length === 0 ? (
      <GutterBox>
        <Typography fontSize="smaller" component="div">
          No positions exists..
        </Typography>
      </GutterBox>
    ) : undefined;

  return (
    <>
      <Spinner loading={loading} />
      {noPositionsPanel}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>{securityPositions}</Box>
      <ReloadDashboardFab />
    </>
  );
};

export default PositionListPanel;
