import { AxiosResponse } from 'axios';

import {
  AuthToken,
  CandleStickBar,
  TrendLine,
  ContractDescription,
  ContractDetails,
  ContractMarketData,
  FavoriteContract,
  FoundationData,
  FundamentalLink,
  FundamentalNote,
  HistoricalDataParams,
  MarketDataParams,
  OpenOrderReport,
  OptionChainParams,
  OptionContract,
  OrderState,
  PlaceOrderReport,
  QuickNote,
  ScheduleInfo,
  ScheduleLog,
  Security,
  TradingClass,
  TradingClassParams,
  Dashboard,
  Position,
  PlaceOrderParams,
  BacktestParams,
  BacktestResult
} from '../types/entities';

import axios from './axios-api';

export const checkBrokerConnection = (): Promise<AxiosResponse<boolean>> => {
  return axios.get<boolean>('/api/broker/check-connection');
};

export const loadDashboard = () => {
  return axios.get<Dashboard>('/api/broker/dashboard');
};

const FUNDAMENTAL_NOTE_ENDPOINT = '/api/fundamental-note';

export const fetchFundamentalNotes = () => {
  return axios.get<FundamentalNote[]>(FUNDAMENTAL_NOTE_ENDPOINT);
};

export const createFundamentalNote = (note: FundamentalNote) => {
  return axios.post(FUNDAMENTAL_NOTE_ENDPOINT, note);
};

export const updateFundamentalNote = (note: FundamentalNote) => {
  return axios.put(`${FUNDAMENTAL_NOTE_ENDPOINT}/${note.id}`, note);
};

export const removeFundamentalNote = (note: FundamentalNote) => {
  return axios.delete(`${FUNDAMENTAL_NOTE_ENDPOINT}/${note.id}`);
};

const FUNDAMENTAL_LINK_ENDPOINT = '/api/fundamental-link';

export const fetchFundamentalLinks = (symbol?: string | undefined) => {
  const params = symbol ? { symbol } : undefined;
  return axios.get<FundamentalLink[]>(FUNDAMENTAL_LINK_ENDPOINT, { params });
};

export const createFundamentalLink = (link: FundamentalLink) => {
  return axios.post(FUNDAMENTAL_LINK_ENDPOINT, link);
};

export const updateFundamentalLink = (link: FundamentalLink) => {
  return axios.put(`${FUNDAMENTAL_LINK_ENDPOINT}/${link.id}`, link);
};

export const removeFundamentalLink = (link: FundamentalLink) => {
  return axios.delete(`${FUNDAMENTAL_LINK_ENDPOINT}/${link.id}`);
};

const TREND_LINE_ENDPOINT = '/api/trend-line';

export const fetchTrendLines = (conId: number) => {
  const params = { conId };
  return axios.get<TrendLine[]>(TREND_LINE_ENDPOINT, { params });
};

export const fetchTrendLinesWithAlert = () => {
  return axios.get<TrendLine[]>(`${TREND_LINE_ENDPOINT}/alert`);
};

export const createTrendLine = (trendLine: TrendLine) => {
  return axios.post(TREND_LINE_ENDPOINT, trendLine);
};

export const updateTrendLine = (trendLine: TrendLine) => {
  return axios.put(`${TREND_LINE_ENDPOINT}/${trendLine.id}`, trendLine);
};

export const removeTrendLine = (trendLine: TrendLine) => {
  return axios.delete(`${TREND_LINE_ENDPOINT}/${trendLine.id}`);
};

export const fetchHistoricalData = (params: HistoricalDataParams) => {
  return axios.post<CandleStickBar[]>('/api/broker/historical-data', params);
};

export const authUser = (username: string, password: string) => {
  const requestBody = { username, password };
  return axios.post<AuthToken>('/api/login', requestBody);
};

export const fetchMarketData = (params: MarketDataParams) => {
  return axios.post<ContractMarketData>('/api/broker/market-data', params);
};

const PLACE_ORDER_ENDPOINT = '/api/broker/order';

export const placeOrder = (args: PlaceOrderParams) => {
  return axios.post<PlaceOrderReport>(`${PLACE_ORDER_ENDPOINT}/place`, args);
};

export const checkOrderMargin = (args: PlaceOrderParams) => {
  return axios.post<OrderState>(`${PLACE_ORDER_ENDPOINT}/check-margin`, args);
};

const OPEN_ORDER_ENDPOINT = '/api/broker/open-order';

export const fetchOpenOrders = () => {
  return axios.get<OpenOrderReport[]>(OPEN_ORDER_ENDPOINT);
};

export const cancelOpenOrder = (report: OpenOrderReport) => {
  return axios.put<number>(`${OPEN_ORDER_ENDPOINT}/${report.orderId}`, report);
};

export const fetchOptionChain = (request: OptionChainParams) => {
  return axios.post<OptionContract[]>('/api/broker/option-chain', request);
};

const FAVORITE_CONTRACT_ENDPOINT = '/api/favorite-contract';

export const fetchFavorites = () => {
  return axios.get<FavoriteContract[]>(FAVORITE_CONTRACT_ENDPOINT);
};

export const addFavorite = (fav: FavoriteContract) => {
  return axios.post<FavoriteContract>(FAVORITE_CONTRACT_ENDPOINT, fav);
};

export const removeFavorite = (fav: FavoriteContract) => {
  return axios.delete(`${FAVORITE_CONTRACT_ENDPOINT}/${fav.id}`);
};

const POSITION_ENDPOINT = '/api/position';

export const createPosition = (pos: Position) => {
  return axios.post<Position>(POSITION_ENDPOINT, pos);
};

export const updatePosition = (pos: Position) => {
  return axios.put(`${POSITION_ENDPOINT}/${pos.id}`, pos);
};

export const completePosition = (pos: Position): Promise<AxiosResponse<Position>> => {
  return axios.put<Position>(`${POSITION_ENDPOINT}/complete/${pos.id}`, pos);
};

export const deletePosition = (pos: Position) => {
  return axios.delete(`${POSITION_ENDPOINT}/${pos.id}`);
};

const SCHEDULE_ENDPOINT = '/api/schedule';

export const isScheduleRunning = (): Promise<AxiosResponse<ScheduleInfo[]>> => {
  return axios.get<ScheduleInfo[]>(SCHEDULE_ENDPOINT);
};

export const fetchScheduleLog = (refId: string): Promise<AxiosResponse<ScheduleLog[]>> => {
  return axios.get<ScheduleLog[]>(`${SCHEDULE_ENDPOINT}/${refId}`);
};

export const toggleScheduleRunning = (): Promise<AxiosResponse<string>> => {
  return axios.post<string>('/api/schedule/toggle');
};

export const fetchSecurityContracts = (security: Security) => {
  return axios.post<ContractDetails[]>('/api/broker/security-contract', security);
};

export const fetchTradingClasses = (params: TradingClassParams) => {
  return axios.post<TradingClass[]>('/api/broker/trading-class', params);
};

export const getPositions = () => {
  return axios.get<Position[]>('/api/position');
};

export const getPositionHistory = (year: number, month: number) => {
  const params: { year: number; month: number } = { year, month };
  return axios.get<Position[]>('/api/position/history', { params });
};

const SECURITY_ENDPOINT = '/api/security';

export const fetchSecurities = () => {
  return axios.get<Security[]>(SECURITY_ENDPOINT);
};

export const createSecurity = (sec: Security) => {
  return axios.post(SECURITY_ENDPOINT, sec);
};

export const updateSecurity = (sec: Security) => {
  return axios.put(`${SECURITY_ENDPOINT}/${sec.id}`, sec);
};

export const removeSecurity = (sec: Security) => {
  return axios.delete(`${SECURITY_ENDPOINT}/${sec.id}`);
};

export const loadFoundationData = () => {
  return axios.get<FoundationData>('/api/foundation-data');
};

export const searchContracts = (params: { query: string }) => {
  return axios.post<ContractDescription[]>('/api/contract-search', params);
};

const QUICK_NOTE_ENDPOINT = '/api/quick-note';

export const fetchQuickNotes = (symbol: string) => {
  return axios.get<QuickNote[]>(`${QUICK_NOTE_ENDPOINT}/${symbol}`);
};

export const createQuickNote = (quickNote: QuickNote) => {
  return axios.post(QUICK_NOTE_ENDPOINT, quickNote);
};

export const deleteQuickNote = (quickNote: QuickNote) => {
  return axios.delete(`${QUICK_NOTE_ENDPOINT}/${quickNote.id}`);
};

export const runOrderAutomationTask = (conId: number) => {
  return axios.get(`/api/run-order-automation-task/${conId}`);
};

export const backtestPosition = (params: BacktestParams) => {
  return axios.post<BacktestResult>('/api/backtest', params);
};
