export enum StrokeType {
  Solid = 0,
  Dotted = 3,
  Dashed = 8
}

export enum LineWidth {
  Narrow = 1,
  Standard = 2,
  Fat = 3
}
