import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import BorderHorizontalIcon from '@mui/icons-material/BorderHorizontal';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { updateParams } from '../../../store/historical-data/reducer';
import { getHistoricalDataStateByKey } from '../../../store/historical-data/selectors';
import { getHistoricalDataAsync } from '../../../store/historical-data/service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { CandleStickBar, Contract, Trade, TrendLine } from '../../../types/entities';
import { BarSize } from '../../../types/enums';
import Spinner from '../../ui/Spinner';
import TrendLineDialog from '../TrendLinePanel/TrendLineDialog';
import { createTrendLineTemplate } from '../TrendLinePanel/utils';

import BarChart from './BarChart';
import ChartQueryForm from './ChartQueryForm';

export interface BarChartPanelParams {
  barChartId: number;
  conId: number;
  symbol?: string;
  secType: string;
  title: string;
  exchange: string;
  entryPrice?: number;
  strikePrice?: number;
  limitPrice?: number;
  stopPrice?: number;
  avgPrice?: number;
  initialBarSize?: BarSize;
  initialDuration?: number;
  showMoreBtn?: boolean;
  hideMenus?: boolean;
  hideTrendLines?: boolean;
  trades?: Trade[] | undefined;
  currency?: string | undefined;
}

const BarChartPanel: FunctionComponent<BarChartPanelParams> = ({
  barChartId,
  conId,
  symbol,
  secType,
  title,
  exchange,
  strikePrice,
  limitPrice,
  stopPrice,
  avgPrice,
  initialBarSize,
  initialDuration,
  showMoreBtn = false,
  hideMenus = false,
  hideTrendLines = false,
  trades = [],
  currency = 'USD'
}: BarChartPanelParams) => {
  const navigate = useNavigate();

  const showMenus = !hideMenus;
  const [showTrendLines, setShowTrendLines] = useState(!hideTrendLines);
  const { trendLines } = useAppSelector((gs) => gs.trendLineState);
  const historicalDataState = useAppSelector((gs) => gs.historicalDataState);
  const historicalDataStateByKey = getHistoricalDataStateByKey(barChartId, conId, historicalDataState);
  const { initialized, loading, params, bars } = historicalDataStateByKey;
  const [selectedTrendLine, setSelectedTrendLine] = useState<TrendLine | undefined>();
  const [showTrendLineDialog, setShowTrendLineDialog] = useState(false);
  const closeTrendLineDialog = () => setShowTrendLineDialog(false);
  const [enableTrendLines, setEnableTrendLines] = useState(false);

  const contract = {
    conId,
    symbol,
    secType,
    exchange,
    currency
  } as Contract;

  const dispatch = useAppDispatch();

  const reloadHistoryData = () => {
    if (params.barSize && params.durationInDays) {
      dispatch(getHistoricalDataAsync(barChartId, params));
    }
  };

  useEffect(() => {
    if (!initialized) {
      const payload = {
        ...params,
        conId,
        secType,
        exchange
      };
      if (initialDuration) {
        payload.durationInDays = initialDuration;
      }
      if (initialBarSize) {
        payload.barSize = initialBarSize;
      }
      dispatch(updateParams({ id: barChartId, conId, params: payload }));
    }
  }, [conId, initialized]);

  useEffect(() => {
    if (initialized) {
      reloadHistoryData();
    }
  }, [initialized, params]);

  const filteredTrendLines = useMemo(() => {
    return trendLines.filter((x) => x.conId === conId);
  }, [trendLines, conId]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChartMouseClick = (yAxis: number, _bar: CandleStickBar) => {
    if (enableTrendLines) {
      const trendLine = createTrendLineTemplate(contract, Number(yAxis.toFixed(2)));
      setSelectedTrendLine(trendLine);
      setShowTrendLineDialog(true);
    }
  };

  const handleMoreChartsBtnClick = () => {
    navigate(`/security/${symbol}?tab=graphs`);
  };

  return (
    <Box>
      <BarChart
        title={title}
        barSize={params.barSize}
        bars={bars}
        strike={strikePrice}
        limit={limitPrice}
        stop={stopPrice}
        avg={avgPrice}
        onChartMouseClick={handleChartMouseClick}
        trendLines={showTrendLines ? filteredTrendLines : undefined}
        trades={trades}
      />

      {showMenus && (
        <>
          <ChartQueryForm sx={{ mx: 1 }} barChartId={barChartId} conId={conId} />

          <Box sx={{ p: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 1 }}>
                {showMoreBtn && (
                  <Tooltip title="More charts.." placement="top-end">
                    <IconButton size="small" onClick={handleMoreChartsBtnClick} color="primary">
                      <MoreHorizIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title={enableTrendLines ? 'Add Trade Line Mode' : 'Graph Mode'} placement="top">
                  <IconButton color="primary" onClick={() => setEnableTrendLines(!enableTrendLines)}>
                    {enableTrendLines ? <BorderHorizontalIcon /> : <CandlestickChartIcon />}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Toggle Show/Hide Trend Lines" placement="top">
                  <IconButton color="primary" onClick={() => setShowTrendLines(!showTrendLines)}>
                    {showTrendLines ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button sx={{ ml: 1 }} variant="outlined" onClick={() => reloadHistoryData()}>
                  Reload Chart {loading && <CircularProgress sx={{ ml: 1 }} color="inherit" size="1rem" />}
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      )}

      {!showMenus && <Spinner loading={loading} />}
      {selectedTrendLine && <TrendLineDialog trendLine={selectedTrendLine} isOpen={showTrendLineDialog} onClose={closeTrendLineDialog} />}
    </Box>
  );
};

export default BarChartPanel;
