import { FunctionComponent } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { INDICATOR_GREEN_COLOR, INDICATOR_RED_COLOR_TEXT } from '../../../../assets/colors';
import RaceTrack, { RaceDriver, RaceSetup } from '../../../../components/panels/RaceTrack';
import { Position, PositionPortfolio } from '../../../../types/entities';
import { formatNum, setDecimalSpaces } from '../../../../utils/currency-utils';

import { getRaceCarDriver, getRaceDuractionPct } from './utils';

interface Props {
  position: Position;
  portfolio: PositionPortfolio;
}

const PositionCollapsedPanel: FunctionComponent<Props> = ({ position, portfolio }: Props) => {
  const sumRealizedPnl = position.trades.map((x) => x.realizedPnl).reduce((a, b) => a + b, 0);

  const durationPct = getRaceDuractionPct(position);

  const raceSetup: RaceSetup = {
    durationPct,
    lossAreaPct: 50
  };

  const currentPriceFormatted = formatNum(portfolio.marketPrice);
  const totalPnl = sumRealizedPnl + portfolio.unrealizedPnl;
  let profitPct = 0;
  let lossPct = 0;
  if (totalPnl > 0) {
    profitPct = position.targetProfit && position.targetProfit > 0 ? (totalPnl / position.targetProfit) * 100 : 0;
  } else if (totalPnl < 0) {
    lossPct = position.targetProfit && position.targetProfit > 0 ? (Math.abs(totalPnl) / position.targetProfit) * 100 : 0;
  }
  const lossPctFormated = lossPct ? `${setDecimalSpaces(lossPct, 0)}%` : '-';
  const profitPctFormated = profitPct ? `${setDecimalSpaces(profitPct, 0)}%` : '-';

  const raceDriver = getRaceCarDriver(position, portfolio.size, profitPct, lossPct);
  const drivers: RaceDriver[] = [];
  if (raceDriver) {
    drivers.push(raceDriver);
  }

  return (
    <Box width="100%" sx={{ p: 1 }}>
      <RaceTrack drivers={drivers} setup={raceSetup} />
      <Grid container width="100%" sx={{ mt: 1 }}>
        <Grid item xs={4} sx={{ textAlign: 'center' }}>
          <Typography variant="caption" sx={{ color: INDICATOR_RED_COLOR_TEXT }}>
            {lossPctFormated}
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: 'center' }}>
          <Typography variant="caption" sx={{ color: (theme) => theme.palette.grey[200] }}>
            {currentPriceFormatted}
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: 'center' }}>
          <Typography variant="caption" sx={{ color: INDICATOR_GREEN_COLOR }}>
            {profitPctFormated}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PositionCollapsedPanel;
