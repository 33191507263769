import { Routes, Route, Navigate } from 'react-router-dom';

import Account from './pages/AccountPage';
import Dashboard from './pages/Dashboard';
import LoginPage from './pages/LoginPage';
import OrderAutomationPage from './pages/OrderAutomationPage';
import PlaceOrderPage from './pages/PlaceOrderPage';
import PositionHistoryPage from './pages/PositionHistoryPage';
import SecurityListPage from './pages/SecurityListPage';
import SecurityPage from './pages/SecurityPage';
import TestDatePage from './pages/TestDatePage';
import WatchlistPage from './pages/WatchlistPage';

export const getRoutes = (authorized: boolean) => {
  if (!authorized) {
    return (
      <Routes>
        <Route path="/login" element={<LoginPage />}></Route>
        <Route path="*" element={<Navigate to="login" replace />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path="/" element={<Dashboard />}></Route>
      <Route path="/place-order/:conId" element={<PlaceOrderPage />}></Route>
      <Route path="/order-automation/:conId" element={<OrderAutomationPage />}></Route>
      {/* <Route path="/order" element={<OrderPage />}></Route> */}
      {/* <Route path="/order-summary/:symbol" element={<OrderSummaryPage />}></Route> */}
      <Route path="/securities" element={<SecurityListPage />}></Route>
      <Route path="/watchlist" element={<WatchlistPage />}></Route>
      <Route path="/security/:symbol" element={<SecurityPage />}></Route>
      {/* <Route path="/back-office" element={<BackOfficePage />}></Route> */}
      <Route path="/account" element={<Account />}></Route>
      <Route path="/history" element={<PositionHistoryPage />}></Route>
      <Route path="/test-date" element={<TestDatePage />}></Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
