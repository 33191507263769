import { FunctionComponent, useEffect, useState } from 'react';

import { Button, Container, Divider, Grid, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import GutterBox from '../../components/ui/GutterBox';
import ResponsiveContainer from '../../components/ui/ResponsiveContainer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getPositionsAsync } from '../../store/position/service';

import AutomationPanel from './AutomationPanel';
import BacktestPanel from './BacktestPanel';
import { createIntialAveragingObj } from './utils';

const OrderAutomationPage: FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const conIdParam = params.conId || 0;
  const conId = Number(conIdParam);

  const { loaded, positions } = useAppSelector((gs) => gs.positionState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loaded) {
      dispatch(getPositionsAsync());
    }
  }, []);

  const position = positions.find((x) => x.details.contract.conId === conId);
  const [averagingForm, setAveragingForm] = useState(position?.averaging ?? createIntialAveragingObj(0));

  useEffect(() => {
    if (position) {
      const copy = position.averaging ? { ...position.averaging } : createIntialAveragingObj(0);
      setAveragingForm(copy);
    }
  }, [position]);

  const goBackBtnClick = () => {
    const canGoBack = location.key !== 'default';
    if (canGoBack) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  const notFound = loaded && !position && (
    <Typography fontSize="smaller" component="div">
      {'Could not find position..'}
    </Typography>
  );

  return (
    <>
      <Container maxWidth={false}>
        <Button sx={{ mt: 2 }} variant="outlined" onClick={goBackBtnClick}>
          Go Back
        </Button>
        <Typography variant="h4" sx={{ my: 3 }}>
          Order Automation
        </Typography>
        {notFound}
      </Container>
      {position && (
        <ResponsiveContainer maxWidth={false}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <GutterBox>
                <Typography variant="h5">Setup</Typography>
              </GutterBox>
              <Divider sx={{ mt: 0.5, mb: 2 }} />
              <AutomationPanel position={position} averagingForm={averagingForm} onUpdateAveragingForm={setAveragingForm} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <GutterBox>
                <Typography variant="h5">Backtesting</Typography>
              </GutterBox>
              <Divider sx={{ mt: 0.5, mb: 2 }} />
              <BacktestPanel position={position} averagingForm={averagingForm} />
            </Grid>
          </Grid>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default OrderAutomationPage;
