import { FunctionComponent } from 'react';

import { Box, Card } from '@mui/material';

import { ORDER_PAGE_DAILY_CHART_ID } from '../../assets/constants';
import BarChartPanel from '../../components/panels/BarChartPanel';
import OptionIndicatorPanel from '../../components/panels/OptionIndicatorPanel';
import { OptionContract, OrderAction, Position } from '../../types/entities';

interface Props {
  orderAction: OrderAction;
  position: Position;
  contractAlias?: string | undefined;
  optionContract?: OptionContract | undefined;
  entryPrice?: number | undefined;
  strikePrice?: number | undefined;
  limitPrice?: number | undefined;
  stopPrice?: number | undefined;
}

const OrderChartPanel: FunctionComponent<Props> = ({
  position,
  optionContract,
  orderAction,
  contractAlias,
  entryPrice,
  strikePrice,
  limitPrice,
  stopPrice
}: Props) => {
  const { symbol, secType, exchange } = position.details.contract;
  const isOption = secType === 'OPT' || secType === 'FOP';
  const conId = isOption ? position.details.underConId : position.details.contract.conId;
  const localSymbol = isOption ? position.details.underSymbol : position.details.contract.localSymbol;

  return (
    <>
      <Card sx={{ my: 2 }}>
        {optionContract && <OptionIndicatorPanel option={optionContract} orderAction={orderAction} />}
        <Box sx={{ p: 1 }}>
          <BarChartPanel
            barChartId={ORDER_PAGE_DAILY_CHART_ID}
            symbol={symbol}
            conId={conId}
            title={contractAlias || localSymbol}
            exchange={exchange}
            entryPrice={entryPrice}
            strikePrice={strikePrice}
            limitPrice={limitPrice}
            stopPrice={stopPrice}
            secType={secType}
          />
        </Box>
      </Card>
    </>
  );
};

export default OrderChartPanel;
