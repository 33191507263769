import { CandleStickBar, HistoricalDataParams } from '../../types/entities';
import { BarSize, WhatToShow } from '../../types/enums';

export interface HistoricalDataStateByKey {
  initialized: boolean;
  loading: boolean;
  loaded: boolean;
  error?: string;
  params: HistoricalDataParams;
  bars: CandleStickBar[];
}

export const initialHistoricalDataStateByKey: HistoricalDataStateByKey = {
  initialized: false,
  loading: false,
  loaded: false,
  error: undefined,
  params: {
    conId: 0,
    exchange: '',
    barSize: BarSize._1_DAY,
    durationInDays: 100,
    whatToShow: WhatToShow.TRADES,
    useRth: false
  },
  bars: []
};

export interface HistoricalDataState {
  [key: string]: HistoricalDataStateByKey;
}

export const initialHistoricalDataState: HistoricalDataState = {};
