import { ContractDetails, OptionRight, Portfolio, PositionOld, Security, TradingClass, WatchtItem } from '../../types/entities';
import { SecurityCategory, SecuritySector } from '../../types/enums';

export interface UserSettingsState {
  useFrozen: boolean;
  useSnapshot: boolean;
  newsPanelCollapsed: boolean;
  indicatorPanelCollapsed: boolean;
  hourGraphCollapsed: boolean;
  dailyGraphCollapsed: boolean;
  selectedSecurityCategory: SecurityCategory;
  selectedSecurity: Security | undefined;
  selectedSecuritySector: SecuritySector | undefined;
  selectedContractDetails?: ContractDetails | undefined;
  securityPanelCollapsed: boolean;
  favoritesPanelCollapsed: boolean;
  trendLinesCollapsed: boolean;
  shortListCollapsed: boolean;
  selectedPortfolio?: Portfolio | undefined;
  selectedContractSection: 'all-contracts' | 'front-runners';
  selectedBackOfficeTab: 'securities' | 'portfolios' | 'fundamentals';
  selectedSecurityTab: 'general' | 'contracts' | 'options' | 'fundamentals' | 'graphs' | 'indicators' | 'positions';
  selectedDashboardTab: 'positions' | 'portfolios' | 'browse';
  selectedExitPosition?: PositionOld | undefined;
  selectedWatchItem?: WatchtItem | undefined;
  optionSettings: {
    [symbol: string]: {
      selectedTradingClass: TradingClass | undefined;
      selectedRight: OptionRight;
    };
  };
}

export const initialState: UserSettingsState = {
  useFrozen: false,
  useSnapshot: false,
  newsPanelCollapsed: false,
  indicatorPanelCollapsed: false,
  hourGraphCollapsed: false,
  dailyGraphCollapsed: false,
  selectedSecurityCategory: SecurityCategory.Shortlist,
  selectedSecurity: undefined,
  selectedSecuritySector: undefined,
  securityPanelCollapsed: true,
  favoritesPanelCollapsed: false,
  trendLinesCollapsed: true,
  shortListCollapsed: false,
  selectedContractSection: 'all-contracts',
  selectedBackOfficeTab: 'securities',
  selectedSecurityTab: 'general',
  selectedDashboardTab: 'positions',
  optionSettings: {}
};
