import { Averaging } from '../../types/entities';
import { AveragingType } from '../../types/enums';

export const createIntialAveragingObj = (initialSize: number) => {
  const initialAveraging: Averaging = {
    type: AveragingType.None,
    useAveraging: false,
    bearish: false,
    flattenThresholdPct: 1,
    averageThresholdPct: 1,
    averageOnLowestPriceOnly: true,
    useExponentialAveraging: false,
    useExponentialFlattening: false,
    useMovingAverage: false,
    movingAveragePeriods: 10,
    initialSize,
    orderSize: 0,
    maxSize: 0,
    marginRequirement: 0,
    keepOpen: false
  };
  return initialAveraging;
};
