import { FunctionComponent, useMemo, useState } from 'react';

// import FavoriteIcon from '@mui/icons-material/Favorite';
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AddCommentIcon from '@mui/icons-material/AddComment';
import SendIcon from '@mui/icons-material/Send';
import { Box, IconButton, TextField, Tooltip, Typography } from '@mui/material';

import { GENERAL_NOTE } from '../../../assets/constants';
import { useAppDispatch } from '../../../store/hooks';
import { createQuickNoteAsync } from '../../../store/quick-note/service';
import { QuickNote, Security } from '../../../types/entities';

import QuickNoteList from './QuickNoteList';

interface Props {
  security?: Security | undefined;
  skeleton?: boolean | undefined;
}

const QuickNotePanel: FunctionComponent<Props> = ({ security, skeleton }) => {
  const [quickNote, setQuickNote] = useState('');
  const [showQuickNote, setShowQuickNote] = useState(false);
  const toggleShowQuickNote = () => {
    setShowQuickNote(!showQuickNote);
  };

  const dispatch = useAppDispatch();

  const symbol = useMemo(() => {
    return security ? security.symbol : GENERAL_NOTE;
  }, [security]);

  function handleAddQuickNoteBtnClick(): void {
    const payload: QuickNote = {
      id: '',
      created: new Date(),
      symbol,
      note: quickNote
    };
    dispatch(createQuickNoteAsync(payload));
    setShowQuickNote(false);
    setQuickNote('');
  }

  const quickNoteForm = (
    <Box sx={{ pt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <TextField
        name="Quick Note"
        size="small"
        label="Quick Note"
        variant="outlined"
        type="text"
        value={quickNote}
        onChange={(e) => setQuickNote(e.target.value)}
        fullWidth
        sx={{ pr: 1 }}
      />
      <IconButton size="small" color="primary" onClick={handleAddQuickNoteBtnClick}>
        <SendIcon />
      </IconButton>
    </Box>
  );

  if (skeleton) {
    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
          <QuickNoteList symbol={symbol} />
          <Tooltip title="Add Quick Note" placement="left-start">
            <IconButton sx={{ mt: 0.5 }} size="small" color="primary" onClick={toggleShowQuickNote}>
              <AddCommentIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
        </Box>
        {showQuickNote && quickNoteForm}
      </>
    );
  }

  return (
    <Box>
      <Box sx={{ pt: 2, px: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6">Quick Notes</Typography>
        </Box>
        <Tooltip title="Add Quick Note" placement="left-start">
          <IconButton sx={{ mt: 0.5 }} size="small" color="primary" onClick={toggleShowQuickNote}>
            <AddCommentIcon sx={{ color: '#fff' }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ px: 2, pt: 2, pb: 1 }}>
        <QuickNoteList symbol={symbol} />
        {showQuickNote && quickNoteForm}
      </Box>
    </Box>
  );
};

export default QuickNotePanel;
