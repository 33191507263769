import { FunctionComponent, useState } from 'react';

import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';

import { GRAY_COLOR, INDICATOR_GREEN_COLOR, INDICATOR_RED_COLOR } from '../../../assets/colors';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { updateTrendLineAsync } from '../../../store/trend-line/service';
import { TrendLine } from '../../../types/entities';
import { BarSize, PriceAlertType } from '../../../types/enums';

interface Props {
  trendLines: TrendLine[];
  onEdit: (trendLine: TrendLine) => void;
  onRemove: (trendLine: TrendLine) => void;
  showSecurityName?: boolean | undefined;
  selectable?: boolean | undefined;
  onSelected?: (trendLine: TrendLine | undefined) => void;
}

const TrendLineTable: FunctionComponent<Props> = ({
  trendLines,
  onEdit,
  onRemove,
  onSelected,
  showSecurityName = false,
  selectable = false
}: Props) => {
  const securityState = useAppSelector((gs) => gs.securityState);
  const { securities } = securityState;
  const [trendLineSelected, setTrendLineSelected] = useState<TrendLine | undefined>();

  const dispatch = useAppDispatch();

  const handleTrendLineSelected = (trendLine: TrendLine) => {
    if (selectable) {
      const item = trendLine.id === trendLineSelected?.id ? undefined : trendLine;
      setTrendLineSelected(item);
      if (onSelected) {
        onSelected(item);
      }
    }
  };

  const handleEditBtnClick = (e: React.MouseEvent<HTMLButtonElement>, trendLine: TrendLine) => {
    e.preventDefault();
    onEdit(trendLine);
  };

  const handleRemoveBtnClick = (e: React.MouseEvent<HTMLButtonElement>, trendLine: TrendLine) => {
    e.preventDefault();
    onRemove(trendLine);
  };

  const handleToggleHideTrendLine = (e: React.MouseEvent<HTMLButtonElement>, trendLine: TrendLine) => {
    e.preventDefault();
    const payload = {
      ...trendLine,
      hidden: !trendLine.hidden
    };
    dispatch(updateTrendLineAsync(payload));
  };

  const getType = (trendLine: TrendLine) => {
    if (!trendLine.specificOnly) {
      return 'All Sizes';
    }
    switch (trendLine.specificBarSize) {
      case BarSize._15_MIN:
        return '15M';
      case BarSize._1_HR:
        return 'Hour';
      case BarSize._1_DAY:
        return 'Daily';
      default:
        return 'N/A';
    }
  };

  const getSecurityName = (symbol: string) => securities.find((x) => x.symbol === symbol)?.name ?? 'N/A';

  const tableRows = trendLines
    .slice()
    .sort((a, b) => ((a.localSymbol ?? a.symbol) > (b.localSymbol ?? b.symbol) ? -1 : 1))
    .sort((a, b) => (a.price > b.price ? -1 : 1))
    .map((trendLine) => {
      return (
        <TableRow
          key={trendLine.id}
          hover={selectable}
          sx={{ cursor: selectable ? 'pointer' : 'default' }}
          onClick={() => handleTrendLineSelected(trendLine)}
          selected={trendLine.id === trendLineSelected?.id}
        >
          {showSecurityName && <TableCell>{getSecurityName(trendLine.symbol)}</TableCell>}
          {/* <TableCell>{trendLine.localSymbol ?? trendLine.symbol}</TableCell> */}
          <TableCell align="center">
            <IconButton
              sx={{
                backgroundColor: trendLine.color,
                height: '16px',
                width: '16px',
                outline: '1px solid white',
                cursor: 'default',
                '&:hover': {
                  backgroundColor: trendLine.color
                }
              }}
              aria-label="Trend Line Color"
            />
          </TableCell>
          <TableCell align="right">{trendLine.price}</TableCell>
          <TableCell>{trendLine.name}</TableCell>
          <TableCell align="center">{getType(trendLine)}</TableCell>
          <TableCell align="right">
            <Tooltip
              title={
                trendLine.alertType === PriceAlertType.Above
                  ? 'Alert Above'
                  : trendLine.alertType === PriceAlertType.Below
                  ? 'Alert Below'
                  : 'No Alert'
              }
            >
              <IconButton size="small">
                {trendLine.alertType === PriceAlertType.None ? (
                  <NotificationsNoneIcon sx={{ color: GRAY_COLOR }} />
                ) : (
                  <NotificationsActiveIcon
                    sx={{ color: trendLine.alertType === PriceAlertType.Above ? INDICATOR_GREEN_COLOR : INDICATOR_RED_COLOR }}
                  />
                )}
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell align="right">
            <Tooltip title="Toggle Hide/Show Trend Line">
              <IconButton color="primary" size="small" onClick={(e) => handleToggleHideTrendLine(e, trendLine)}>
                {trendLine.hidden === true ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell align="right" sx={{ width: '50px', px: 1 }}>
            <Button size="small" variant="outlined" onClick={(e) => handleEditBtnClick(e, trendLine)}>
              Edit
            </Button>
          </TableCell>
          <TableCell align="right" sx={{ px: 1 }}>
            <Button size="small" variant="outlined" color="error" onClick={(e) => handleRemoveBtnClick(e, trendLine)}>
              Remove
            </Button>
          </TableCell>
        </TableRow>
      );
    });

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            {showSecurityName && <TableCell width="150px">Security</TableCell>}
            {/* <TableCell width="70px" sx={{ whiteSpace: 'nowrap' }}>
              Local Symbol
            </TableCell> */}
            <TableCell width="70px" align="center">
              Color
            </TableCell>
            <TableCell width="100px" align="right">
              Price
            </TableCell>
            <TableCell>Comment</TableCell>
            <TableCell align="center" width="100px" sx={{ whiteSpace: 'nowrap' }}>
              Bar Size
            </TableCell>
            <TableCell width="70px"></TableCell>
            <TableCell width="70px"></TableCell>
            <TableCell width="70px"></TableCell>
            <TableCell width="70px"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableRows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default TrendLineTable;
