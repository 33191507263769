import { FunctionComponent, useMemo, useState } from 'react';

import { Box, Button, Card, CircularProgress } from '@mui/material';

import { BACKTEST_PAGE_CHART_ID, POSTION_BACKTEST_COLOR } from '../../../assets/constants';
import { useBacktestPosition } from '../../../components/hooks/useBacktestPostion';
import BarChartPanel from '../../../components/panels/BarChartPanel';
import ChartQueryForm from '../../../components/panels/BarChartPanel/ChartQueryForm';
import PositionPanel from '../../../components/panels/PositionPanel';
import { getHistoricalDataStateByKey } from '../../../store/historical-data/selectors';
import { useAppSelector } from '../../../store/hooks';
import { Averaging, BacktestParams, BacktestResult, CandleStickBar, Position } from '../../../types/entities';
import { BarSize } from '../../../types/enums';
import { deepCopyPosition } from '../../../utils/position-utils';

interface Props {
  position: Position;
  averagingForm: Averaging;
}

const BacktestPanel: FunctionComponent<Props> = ({ position, averagingForm }: Props) => {
  const { loading, backtestPositionAsync } = useBacktestPosition();
  const [backtestResult, setBacktestResult] = useState<BacktestResult | undefined>();

  const contract = position.details.contract;
  const historicalDataState = useAppSelector((gs) => gs.historicalDataState);
  const historyData = getHistoricalDataStateByKey(BACKTEST_PAGE_CHART_ID, contract.conId, historicalDataState);

  const bars: CandleStickBar[] = useMemo(() => {
    if (!historyData || !historyData.bars) {
      return [];
    }
    return historyData.bars.map((bar) => ({
      x: new Date(bar.x),
      y: bar.y
    }));
  }, [historyData]);

  async function handleBacktestBtnClick() {
    // clean
    const copy = deepCopyPosition(position);
    copy.logs = [];
    copy.trades = [];
    copy.averaging = averagingForm;
    const backtestParams: BacktestParams = {
      position: copy,
      candleStickBars: bars
    };
    const pos = await backtestPositionAsync(backtestParams);
    setBacktestResult(pos);
  }

  return (
    <>
      <ChartQueryForm title="Backtest Period" barChartId={BACKTEST_PAGE_CHART_ID} conId={contract.conId} expanded={true}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" color="success" onClick={() => handleBacktestBtnClick()}>
            Backtest {loading && <CircularProgress sx={{ ml: 1 }} color="inherit" size="1rem" />}
          </Button>
        </Box>
      </ChartQueryForm>

      <Card sx={{ mt: 2, p: 1 }}>
        <BarChartPanel
          barChartId={BACKTEST_PAGE_CHART_ID}
          symbol={contract.symbol}
          conId={contract.conId}
          title={contract.localSymbol}
          exchange={contract.exchange}
          secType={contract.secType}
          initialBarSize={BarSize._1_HR}
          initialDuration={5}
          trades={backtestResult?.position.trades}
          hideMenus={true}
          hideTrendLines={true}
        />
      </Card>
      {backtestResult && (
        <PositionPanel mt={2} position={backtestResult.position} portfolio={backtestResult.portfolio} bg={POSTION_BACKTEST_COLOR} />
      )}
    </>
  );
};

export default BacktestPanel;
