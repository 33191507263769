import { Contract, TrendLine } from '../../../types/entities';
import { BarSize, PriceAlertType } from '../../../types/enums';

export const createTrendLineTemplate = (contract: Contract, yAxis?: number | undefined): TrendLine => {
  const exchange = contract.exchange ?? contract.primaryExch ?? '';
  const { secType } = contract;
  return {
    id: '',
    conId: contract.conId,
    symbol: contract.symbol,
    localSymbol: contract.localSymbol,
    exchange,
    secType,
    currency: contract.currency,
    name: '',
    color: '#ff9800',
    price: yAxis ?? 0,
    alertType: PriceAlertType.None,
    specificOnly: false,
    specificBarSize: BarSize._1_DAY
  };
};
