import { FunctionComponent, useMemo, useState } from 'react';

import { Grid, TextField, Box, CircularProgress, Button, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';

import { useAppDispatch } from '../../store/hooks';
import { updatePositionAsync } from '../../store/position/service';
import { Position } from '../../types/entities';
import { deepCopyPosition } from '../../utils/position-utils';
import { CustomizedMarketDataResult, useRefreshMarketData } from '../hooks/useRefreshMarketData';
import AccordionWrapper from '../ui/AccordionWrapper';

interface Props {
  sx?: object;
  position: Position;
}
const ContractInsightPanel: FunctionComponent<Props> = ({ sx, position }) => {
  const [panelExpanded, setPanelExpanded] = useState(false);
  const { refreshMarketData, loading } = useRefreshMarketData();
  const dispatch = useAppDispatch();

  const onRefreshMarketDataCallback = (result: CustomizedMarketDataResult | undefined) => {
    if (result) {
      const copy = deepCopyPosition(position);
      copy.currentMarketPrice = Number(result.marketPrice.toFixed(4));
      copy.averageVolume90Days = result.averageVolume90Days;
      copy.historicalVolatilityPct = result.historicalVolatilityPct;
      copy.impliedVolatilityPct = result.impliedVolatilityPct;
      dispatch(updatePositionAsync(copy));
    }
  };

  const handleRefreshBtnClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    await refreshMarketData(position.details.contract, onRefreshMarketDataCallback, false);
  };

  const pricePctRatio = useMemo(() => {
    if (position.currentMarketPrice && position.currentMarketPrice > 0) {
      return (100 / position.currentMarketPrice).toFixed(2);
    }
    return '-';
  }, [position.currentMarketPrice]);

  return (
    <AccordionWrapper title="Contract Insight" sx={sx} collapsed={!panelExpanded} onChange={() => setPanelExpanded(!panelExpanded)}>
      <Grid p={2} container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <TextField
            size="small"
            label="Contract Multiplier"
            variant="outlined"
            type="number"
            value={position.details.contract.multiplier || 1}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            label="Volume 90d"
            variant="outlined"
            type="number"
            value={position.averageVolume90Days}
            helperText=""
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            label="Daily hist. VLT %"
            variant="outlined"
            type="number"
            value={position.historicalVolatilityPct}
            helperText=""
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            label="Daily impl. VLT %"
            variant="outlined"
            type="number"
            value={position.impliedVolatilityPct}
            helperText=""
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel>Current Market Price</InputLabel>
            <OutlinedInput
              value={position.currentMarketPrice}
              size="small"
              type="number"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              label="Current Market Price"
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField size="small" label="$100 Pct Ratio" variant="outlined" type="text" value={pricePctRatio} helperText="" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Button variant="outlined" onClick={handleRefreshBtnClick}>
              Update Insight {loading && <CircularProgress sx={{ ml: 1 }} color="inherit" size="1rem" />}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </AccordionWrapper>
  );
};

export default ContractInsightPanel;
