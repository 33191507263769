import { ContractDetails, OptionContract, Position } from '../types/entities';

export const deepCopyPosition = (position: Position) => {
  return {
    ...position,
    details: {
      ...position.details,
      contract: {
        ...position.details.contract
      }
    },
    averaging: position.averaging ? { ...position.averaging } : undefined,
    simulator: position.simulator ? { ...position.simulator } : undefined,
    trades: [...position.trades],
    logs: [...position.logs]
  };
};

export const createUnderlyingPosition = (details: ContractDetails): Position => {
  const underSymbol = details.underSymbol ?? details.contract.symbol;
  const underSecType = details.underSymbol ?? details.contract.secType;
  const contractMonth = details.contractMonth ?? '';
  const lastTradeTime = details.lastTradeTime ?? '';
  const realExpirationDate = details.realExpirationDate ?? '';
  const lastTradeDateOrContractMonth = details.contract.lastTradeDateOrContractMonth ?? '';
  const multiplier = details.contract.multiplier ?? '';
  return {
    id: '',
    sortOrder: 0,
    created: new Date().toISOString(), // redux: not accepting non-serializable
    details: {
      ...details,
      underSymbol,
      underSecType,
      contractMonth,
      lastTradeTime,
      realExpirationDate,
      contract: {
        ...details.contract,
        lastTradeDateOrContractMonth,
        multiplier
      }
    },
    targetProfit: 0,
    useDynamicStopLoss: false,
    dynamicStopLoss: 0,
    isBacktest: false,
    closed: false,
    completed: false,
    trades: [],
    logs: []
  };
};

export const createOptionPosition = (option: OptionContract): Position => {
  const { details } = option;
  const underSymbol = details.underSymbol ?? details.contract.symbol;
  const underSecType = details.underSymbol ?? details.contract.secType;
  const contractMonth = details.contractMonth ?? '';
  const lastTradeTime = details.lastTradeTime ?? '';
  const realExpirationDate = details.realExpirationDate ?? '';
  const lastTradeDateOrContractMonth = details.contract.lastTradeDateOrContractMonth ?? '';
  const multiplier = details.contract.multiplier ?? '';
  return {
    id: '',
    sortOrder: 0,
    created: new Date().toISOString(), // redux: not accepting non-serializable
    details: {
      ...details,
      underSymbol,
      underSecType,
      contractMonth,
      lastTradeTime,
      realExpirationDate,
      contract: {
        ...details.contract,
        lastTradeDateOrContractMonth,
        multiplier
      }
    },
    optionExpireDate: option.expires,
    targetProfit: 0,
    useDynamicStopLoss: false,
    dynamicStopLoss: 0,
    isBacktest: false,
    closed: false,
    completed: false,
    trades: [],
    logs: []
  };
};

export const hasPositionEnded = (position: Position) => {
  const { closed, optionExpireDate } = position;
  if (closed) {
    return true;
  }
  if (!optionExpireDate) {
    return false;
  }
  const now = new Date();
  const exDt = new Date(optionExpireDate);
  return now > exDt;
};
