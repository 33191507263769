import { FunctionComponent } from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import dayjs from 'dayjs';

import { INDICATOR_GREEN_COLOR, INDICATOR_RED_COLOR } from '../../../../assets/colors';
import { OrderAction, Position, Trade } from '../../../../types/entities';
import { setThousenSeparator } from '../../../../utils/currency-utils';
import ShowGraphIconButton from '../../../ui/ShowGraphIconButton';

interface Props {
  position: Position;
}

const PositionTradeTable: FunctionComponent<Props> = ({ position }: Props) => {
  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>Order ID</TableCell>
        <TableCell>Filled</TableCell>
        <TableCell>Action</TableCell>
        <TableCell align="center">Size</TableCell>
        <TableCell align="right">Filled Price</TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="right">Comm.</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">
          Rlz. PnL
        </TableCell>
      </TableRow>
    </TableHead>
  );

  const getFormattedDate = (isoDate: string | Date | undefined) => {
    if (!!isoDate) {
      const dt = new Date(isoDate);
      return dayjs(dt).format('MM/DD HH:mm A');
    }
    return 'N/A';
  };

  const orderRows = position.trades.map((trade: Trade, i) => {
    const action: OrderAction = trade.action === 1 ? 'BUY' : 'SELL';
    const price = `$${trade.price.toFixed(2)}`;
    const commission = trade.commission ? `$${trade.commission.toFixed(2)}` : '-';
    const filledDate = getFormattedDate(trade.filled);
    const realizedPnlFormatted = `$${setThousenSeparator(trade.realizedPnl.toFixed(0))}`;
    const realizedPnlColor = trade.realizedPnl > 0 ? INDICATOR_GREEN_COLOR : trade.realizedPnl < 0 ? INDICATOR_RED_COLOR : 'Inherit';
    return (
      <TableRow key={`${trade.conId}-${i}`} sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
        <TableCell>{trade.orderId}</TableCell>
        <TableCell>{filledDate}</TableCell>
        <TableCell>{action}</TableCell>
        <TableCell align="center">{trade.size}</TableCell>
        <TableCell align="right">{price}</TableCell>
        <TableCell align="center">
          <ShowGraphIconButton position={position} trades={[trade]} />
        </TableCell>
        <TableCell align="right">{commission}</TableCell>
        <TableCell align="right" sx={{ color: realizedPnlColor }}>
          {realizedPnlFormatted}
        </TableCell>
      </TableRow>
    );
  });

  return (
    <TableContainer>
      <Table size="small">
        {tableHeader}
        <TableBody>{orderRows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default PositionTradeTable;
