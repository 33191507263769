import { FunctionComponent, useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Skeleton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { INDICATOR_VLT_COLOR } from '../../assets/colors';
import { getFundamentalNotesAsync } from '../../store/fundamental-note/service';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { FundamentalNote } from '../../types/entities';
import { FundamentalTrend, FundamentalType, PeriodMonthPart } from '../../types/enums';
import { getMonthName } from '../../utils/month-utils';

const SeasonalAccordion: FunctionComponent = () => {
  const navigate = useNavigate();

  const now = new Date();
  const oneBasedMonth = now.getMonth() + 1;
  const day = now.getDate();
  const MONTH_BEGINNING_TRESHOLD = 10;
  const MONTH_END_TRESHOLD = 20;

  const { securities } = useAppSelector((gs) => gs.securityState);
  const { loading: notesLoading, loaded: notesLoaded, notes } = useAppSelector((gs) => gs.fundamentalNoteState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!notesLoading && !notesLoaded) {
      dispatch(getFundamentalNotesAsync());
    }
  }, []);

  const getCurrentPeriod = () => {
    const monthName = getMonthName(oneBasedMonth).toUpperCase();
    let partName = '';
    if (day < MONTH_BEGINNING_TRESHOLD) {
      partName = 'BEGINNING OF ';
    } else if (day > MONTH_END_TRESHOLD) {
      partName = 'END OF ';
    }
    return `${partName}${monthName}`;
  };

  type ChipColor = 'info' | 'success' | 'warning' | 'error';
  const getChipColor = (trend: FundamentalTrend): ChipColor => {
    switch (trend) {
      case FundamentalTrend.Volatile:
        return 'warning';
      case FundamentalTrend.Bullish:
        return 'success';
      case FundamentalTrend.Bearish:
        return 'error';
      default:
        return 'info';
    }
  };

  const handleNoteChipClick = (note: FundamentalNote) => {
    navigate(`/security/${note.symbol}?tab=fundamentals`);
  };

  const currentPeriodNotes = notes
    .filter((x) => x.periodMonth === oneBasedMonth && x.type === FundamentalType.Period)
    .filter((x) => (day < MONTH_BEGINNING_TRESHOLD ? x.periodMonthPart !== PeriodMonthPart.End : x))
    .filter((x) => (day > MONTH_END_TRESHOLD ? x.periodMonthPart !== PeriodMonthPart.Beginning : x));

  const periodChips = currentPeriodNotes.map((x) => {
    const securityName = securities.find((y) => y.symbol === x.symbol)?.name || '';
    const monthPart = PeriodMonthPart[x.periodMonthPart];
    const label = `${securityName} - ${monthPart}`;
    return <Chip key={x.id} label={label} color={getChipColor(x.trend)} onClick={() => handleNoteChipClick(x)} />;
  });

  const currentEventNotes = notes
    .filter((x) => x.periodMonth === oneBasedMonth && x.type === FundamentalType.Event && x.eventDate >= day)
    .sort((a, b) => (a.eventDate < b.eventDate ? -1 : 1));

  const eventChips = currentEventNotes.map((x) => {
    const securityName = securities.find((y) => y.symbol === x.symbol)?.name || '';
    const label = `${securityName} - ${x.eventDate}.`;
    return <Chip key={x.id} label={label} color={getChipColor(x.trend)} onClick={() => handleNoteChipClick(x)} />;
  });

  const numberOfFundamentals = periodChips.length + eventChips.length;

  const [expanded, setExpanded] = useState(false);

  if (notesLoading) {
    return <Skeleton sx={{ m: 2 }} variant="rounded" animation="wave" height={80} />;
  }

  return (
    <Accordion disableGutters={true} expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" color={notes.length > 0 ? INDICATOR_VLT_COLOR : 'inherit'}>
          Historical Good Months
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{ mb: 2 }} variant="caption" component="div">
          PERIOD:{' '}
          <Typography variant="caption" color="primary">
            {getCurrentPeriod()}
          </Typography>
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          {periodChips.length > 0 && (
            <Box>
              <Typography sx={{ display: 'inline-block', mb: 1 }} variant="caption">
                PERIOD NOTES
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>{periodChips}</Box>
            </Box>
          )}
          {eventChips.length > 0 && (
            <Box>
              <Typography sx={{ display: 'inline-block', mb: 1 }} variant="caption">
                EVENT NOTES
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>{eventChips}</Box>
            </Box>
          )}
          {numberOfFundamentals === 0 && <Typography fontSize="smaller">No notes for current period..</Typography>}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default SeasonalAccordion;
