const getFridayOffset = (date: Date) => {
  const day = date.getDay();
  let offset = 0;
  switch (day) {
    case 0:
      offset = 2;
      break;
    case 1:
      offset = 3;
      break;
    case 2:
      offset = 4;
      break;
    case 3:
      offset = 5;
      break;
    case 4:
      offset = 6;
      break;
    case 5:
      offset = 7;
      break;
    case 6:
      offset = 1;
      break;
  }
  return offset;
};

export const getPreviousFriday = (date: Date) => {
  const offset = getFridayOffset(date);
  const prevFriday = new Date(date);
  prevFriday.setDate(date.getDate() - offset);
  return prevFriday;
};

export const getNextFriday = (date: Date) => {
  const offset = getFridayOffset(date);
  const nextFriday = new Date(date);
  nextFriday.setDate(date.getDate() + offset);
  return nextFriday;
};
