import { FunctionComponent } from 'react';

import { Box, Divider, Tooltip, Typography } from '@mui/material';

import { GRAY_COLOR, INDICATOR_GREEN_COLOR, INDICATOR_RED_COLOR_TEXT } from '../../../../assets/colors';
import { Position, PositionPortfolio } from '../../../../types/entities';
import { AveragingType } from '../../../../types/enums';
import { setThousenSeparator } from '../../../../utils/currency-utils';

interface Props {
  position: Position;
  portfolio: PositionPortfolio;
}

const PositionSubHeader: FunctionComponent<Props> = ({ position, portfolio }) => {
  const sumRealizedPnl = position.trades.map((x) => x.realizedPnl).reduce((a, b) => a + b, 0);
  const realizedAmountFormatted = `$${setThousenSeparator(sumRealizedPnl.toFixed(0))}`;
  const marketValue = position.details.contract.secType !== 'FUT' ? portfolio.marketValue : 0; // inconsistant value from FUT contrcats
  const marketValueAmountFormatted = `$${setThousenSeparator(marketValue.toFixed(0))}`;
  const unrealizedAmountFormatted = `$${setThousenSeparator(portfolio.unrealizedPnl.toFixed(0))}`;
  const targetProfitFormatted = `$${setThousenSeparator(position.targetProfit.toFixed(0))}`;

  const avgInfoEl = !!position.averaging && position.averaging.type !== AveragingType.None && (
    <Tooltip title="Averaging: Initial Size - Order Size - Max Size" sx={{ cursor: 'pointer' }}>
      <Typography variant="caption">
        {position.averaging.initialSize}-{position.averaging.orderSize}-{position.averaging.maxSize}
      </Typography>
    </Tooltip>
  );

  return (
    <Box
      sx={{
        px: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        gap: 2,
        minHeight: '34px'
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography variant="caption">
          UNRLZ{' '}
          <span
            style={{
              color:
                portfolio.unrealizedPnl > 0 ? INDICATOR_GREEN_COLOR : portfolio.unrealizedPnl < 0 ? INDICATOR_RED_COLOR_TEXT : 'inherit'
            }}
          >
            {unrealizedAmountFormatted}
          </span>
        </Typography>
        <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
        <Typography variant="caption">
          RLZ{' '}
          <span style={{ color: sumRealizedPnl > 0 ? INDICATOR_GREEN_COLOR : sumRealizedPnl < 0 ? INDICATOR_RED_COLOR_TEXT : 'inherit' }}>
            {realizedAmountFormatted}
          </span>
        </Typography>
        <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
        <Typography variant="caption">
          <span style={{ color: GRAY_COLOR }}>MKTV {marketValueAmountFormatted}</span>
        </Typography>
      </Box>
      <Box py={0.5} display="flex" alignItems="center">
        {avgInfoEl}
        <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
        <Tooltip title="Target Profit" sx={{ cursor: 'pointer' }}>
          <Typography variant="caption"> {targetProfitFormatted}</Typography>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default PositionSubHeader;
