import { FunctionComponent } from 'react';

import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip
} from '@mui/material';

import { OrderState, PlaceOrderParams, Position } from '../../types/entities';
import { Logger } from '../../utils/Logger';
import { useCheckMarginAsync } from '../hooks/useCheckMarginAsync';

interface Props {
  position: Position;
  orderSize: number;
  orderAction: 'BUY' | 'SELL';
  marginRequirement: number;
  onMarginRequirementUpdate: (marginRequirement: number) => void;
  helperText?: string | undefined;
}

const MarginRequirementController: FunctionComponent<Props> = ({
  position,
  orderAction,
  orderSize,
  marginRequirement,
  onMarginRequirementUpdate,
  helperText
}) => {
  const { checkMarginAsync, loading: checkMarginLoading } = useCheckMarginAsync();

  const checkMarginRequirementCallback = (orderState: OrderState) => {
    Logger.log(orderState);
    const { initMarginChange } = orderState;
    const initMarginChangeNum = Number(initMarginChange) || 0;
    const initMarginChangeNumFormatted = Number(initMarginChangeNum.toFixed(0));
    onMarginRequirementUpdate(initMarginChangeNumFormatted);
  };

  // todo: fix loading variable for update margin req
  const handleUpdateMarginReqBtnClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const params = {
      details: {
        ...position.details,
        contract: { ...position.details.contract }
      },
      orderAction,
      orderType: 'MKT',
      orderSize,
      triggerPrice: 0,
      outsideRth: false,
      tif: 'GTC',
      usePriceCondition: false,
      underlyingTriggerPrice: 0,
      isAbove: false
    } as PlaceOrderParams;

    checkMarginAsync(params, checkMarginRequirementCallback);
  };

  return (
    <Box sx={{ display: 'flex', justifyColumns: 'center', alignItems: 'flex-start', gap: 1 }}>
      <FormControl fullWidth>
        <InputLabel>Margin Requirement</InputLabel>
        <OutlinedInput
          value={marginRequirement}
          onChange={(e) => onMarginRequirementUpdate(Number(e.target.value))}
          size="small"
          type="number"
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          label="Margin Requirement"
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
      <ButtonGroup>
        <Tooltip title="Check Margin Requirement">
          <Button sx={{ pb: 1, px: 1 }} onClick={handleUpdateMarginReqBtnClick}>
            {checkMarginLoading ? <CircularProgress size={24} /> : <PriceCheckIcon />}
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Box>
  );
};

export default MarginRequirementController;
