import { useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getContractsByUnderlyingSymbolState } from '../../store/security-contract/selectors';
import { getSecurityContractsAsync } from '../../store/security-contract/service';
import { Contract, ContractDetails, Security } from '../../types/entities';

export interface SecurityContractList {
  contracts: ContractDetails[];
  favoriteOrDefault: Contract | undefined;
  loading: boolean;
  initialLoaded: boolean;
  loaded: boolean;
}

const useSecurityContractList = (security?: Security): SecurityContractList => {
  const symbol = security?.symbol || '';
  if (!symbol) {
    return { contracts: [], favoriteOrDefault: undefined, loading: false, initialLoaded: false, loaded: false };
  }

  const { loaded: favoritesLoaded } = useAppSelector((gs) => gs.foundationDataState);
  const { favorites } = useAppSelector((gs) => gs.favoriteContractState);
  const secContractState = useAppSelector((gs) => gs.securityContractState);
  const { contracts, initialLoaded, loaded } = getContractsByUnderlyingSymbolState(secContractState, symbol);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (security && !loaded) {
      dispatch(getSecurityContractsAsync(security));
    }
  }, [security]);

  const getFavoriteContract = () => {
    const favContracts = favorites.map((x) => x.details);
    for (let i = 0; i < contracts.length; i++) {
      const curr = contracts[i].contract;
      const fav = favContracts.find((x) => x.contract.conId === curr.conId);
      if (fav) {
        return fav;
      }
    }
  };

  const contract = useMemo(() => {
    if (contracts.length === 1) {
      return contracts[0].contract;
    }
    const fav = getFavoriteContract();
    if (fav) {
      return fav.contract;
    }
  }, [contracts, favorites, getFavoriteContract]);

  const loading = (!!contract && !loaded) || !favoritesLoaded;

  return {
    contracts: contracts,
    favoriteOrDefault: contract,
    loading,
    initialLoaded,
    loaded
  };
};

export default useSecurityContractList;
