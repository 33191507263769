import { FunctionComponent, useEffect, useState } from 'react';

import {
  Grid,
  FormControlLabel,
  Switch,
  TextField,
  Divider,
  Box,
  CircularProgress,
  Typography,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  ButtonGroup,
  Tooltip,
  FormHelperText
} from '@mui/material';

import { GOLD_COLOR } from '../../assets/colors';
import { useRunOrderAutomationTask } from '../../components/hooks/useTestRunAveragingTask';
import ContractInsightPanel from '../../components/panels/ContactInsightPanel';
import AccordionWrapper from '../../components/ui/AccordionWrapper';
import GutterBox from '../../components/ui/GutterBox';
import MarginRequirementController from '../../components/ui/MarginCheckController';
import { useAppDispatch } from '../../store/hooks';
import { updatePositionAsync } from '../../store/position/service';
import { Position, Averaging } from '../../types/entities';
import { AveragingType } from '../../types/enums';
import { deepCopyPosition } from '../../utils/position-utils';

import SimulatorPanel from './SimulatorPanel';

interface Props {
  position: Position;
  averagingForm: Averaging;
  onUpdateAveragingForm: (averagingForm: Averaging) => void;
}
const AutomationPanel: FunctionComponent<Props> = ({ position, averagingForm, onUpdateAveragingForm }) => {
  const [useTargetProfit] = useState(true);
  const [targetProfit, setTargetProfit] = useState(position.targetProfit);
  const [useDynamicStopLoss, setUseDynamicStopLoss] = useState(position.useDynamicStopLoss);
  const [dynamicStopLoss, setDynamicStopLoss] = useState(position.dynamicStopLoss);
  const [orderPnlPanelExpanded, setOrderPnlPanelExpanded] = useState(true);
  const [orderAveragingPanelExpanded, setOrderAveragingPanelExpanded] = useState(averagingForm.type !== AveragingType.None);

  useEffect(() => {
    setTargetProfit(position.targetProfit);
    setUseDynamicStopLoss(position.useDynamicStopLoss);
    setDynamicStopLoss(position.dynamicStopLoss);
  }, [position]);

  const dispatch = useAppDispatch();

  const isDeveloperMode = process.env.REACT_APP_DEVELOPER_MODE === 'true';
  const { runOrderAutomationTask, loading: runTaskLoading } = useRunOrderAutomationTask();
  const handleRunOrderAutomationTaskBtnClick = () => {
    runOrderAutomationTask(position.details.contract.conId);
  };

  const handleSaveBtnClick = () => {
    const copy = deepCopyPosition(position);
    copy.targetProfit = targetProfit;
    copy.useDynamicStopLoss = useDynamicStopLoss;
    copy.dynamicStopLoss = dynamicStopLoss;
    copy.averaging = averagingForm;
    dispatch(updatePositionAsync(copy));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUpdateAveragingForm = (field: keyof Averaging, value: any) => {
    const copy = {
      ...averagingForm,
      [field]: value
    } as Averaging;
    onUpdateAveragingForm(copy);
  };

  // options are bullish based on BUY-CALL and SELL-PUT
  const right = position.details.contract.right;
  const bullishLabel = !!right ? `BUY ${right === 'C' ? 'CALL' : 'PUT'}` : 'Bullish';
  const bearishLabel = !!right ? `SELL ${right === 'C' ? 'CALL' : 'PUT'}` : 'Bearish';

  return (
    <>
      <ContractInsightPanel sx={{ my: 2 }} position={position} />

      <AccordionWrapper
        sx={{ my: 2 }}
        title={
          <Typography variant="h6" color={useTargetProfit || useDynamicStopLoss ? GOLD_COLOR : 'inherit'}>
            Order PnL
          </Typography>
        }
        collapsed={!orderPnlPanelExpanded}
        onChange={() => setOrderPnlPanelExpanded(!orderPnlPanelExpanded)}
      >
        <Grid p={2} container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Tooltip title="Target profit is mandatory. The racing-track length is based on its value.">
              <FormControlLabel
                control={<Switch sx={{ ml: 1 }} checked={true} />}
                label={<Typography variant="body2">Use Target Profit (read only)</Typography>}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel>Target Profit</InputLabel>
              <OutlinedInput
                value={targetProfit}
                onChange={(e) => setTargetProfit(Number(e.target.value))}
                size="small"
                type="number"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                label="Target Profit"
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid p={2} pt={1} container spacing={2} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={<Switch sx={{ ml: 1 }} onChange={() => setUseDynamicStopLoss(!useDynamicStopLoss)} checked={useDynamicStopLoss} />}
              label={<Typography variant="body2">Use Dynamic Stop Loss</Typography>}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              label="Dynamic Stop Loss"
              variant="outlined"
              type="number"
              value={dynamicStopLoss}
              onChange={(e) => setDynamicStopLoss(Number(e.target.value))}
              helperText="Dynamic order size maintained by scheduled task"
              fullWidth
              disabled={!useDynamicStopLoss}
            />
          </Grid>
        </Grid>
        <Box sx={{ mb: 2, mr: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button variant="contained" onClick={() => handleSaveBtnClick()}>
            Save
          </Button>
        </Box>
      </AccordionWrapper>

      <AccordionWrapper
        sx={{ my: 2 }}
        title={
          <Typography variant="h6" color={averagingForm.type !== AveragingType.None ? GOLD_COLOR : 'inherit'}>
            Order Averaging
          </Typography>
        }
        collapsed={!orderAveragingPanelExpanded}
        onChange={() => setOrderAveragingPanelExpanded(!orderAveragingPanelExpanded)}
      >
        <Box>
          <Grid p={2} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="caption" component="div" sx={{ mb: 0.5 }}>
                AVERAGING TYPE
              </Typography>
              <ButtonGroup variant="outlined" disableElevation fullWidth>
                <Button
                  onClick={() => handleUpdateAveragingForm('type', AveragingType.None)}
                  variant={averagingForm.type === AveragingType.None ? 'contained' : 'outlined'}
                >
                  None
                </Button>
                <Button
                  onClick={() => handleUpdateAveragingForm('type', AveragingType.Trade)}
                  variant={averagingForm.type === AveragingType.Trade ? 'contained' : 'outlined'}
                >
                  Trades
                </Button>
                <Button
                  onClick={() => handleUpdateAveragingForm('type', AveragingType.Moving)}
                  variant={averagingForm.type === AveragingType.Moving ? 'contained' : 'outlined'}
                >
                  Moving
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>

          <Divider />
          <Typography variant="button" component="div" pt={2} pl={2}>
            ORDER SETUP
          </Typography>
          <Grid p={2} container spacing={2}>
            <Grid item xs={12}>
              <ButtonGroup variant="outlined" disableElevation fullWidth>
                <Button
                  onClick={() => handleUpdateAveragingForm('bearish', false)}
                  variant={!averagingForm.bearish ? 'contained' : 'outlined'}
                >
                  {bullishLabel}
                </Button>
                <Button
                  onClick={() => handleUpdateAveragingForm('bearish', true)}
                  variant={averagingForm.bearish ? 'contained' : 'outlined'}
                >
                  {bearishLabel}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid p={2} container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                label="Initial Size"
                variant="outlined"
                type="number"
                value={averagingForm.initialSize}
                onChange={(e) => handleUpdateAveragingForm('initialSize', Number(e.target.value))}
                helperText="Initial Trade Size"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                label="Order Size"
                variant="outlined"
                type="number"
                value={averagingForm.orderSize}
                onChange={(e) => handleUpdateAveragingForm('orderSize', Number(e.target.value))}
                helperText="Flattenging or averaging order size"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                label="Max Size"
                variant="outlined"
                type="number"
                value={averagingForm.maxSize}
                onChange={(e) => handleUpdateAveragingForm('maxSize', Number(e.target.value))}
                helperText="Threshold for number of buy-ins"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MarginRequirementController
                position={position}
                orderSize={averagingForm.maxSize}
                orderAction={averagingForm.bearish ? 'SELL' : 'BUY'}
                marginRequirement={averagingForm.marginRequirement}
                onMarginRequirementUpdate={(marginReq) => handleUpdateAveragingForm('marginRequirement', marginReq)}
                helperText="Based on max size"
              />
            </Grid>
          </Grid>

          <Divider />
          <Typography variant="button" component="div" pt={2} pl={2}>
            Thresholds
          </Typography>
          <Grid p={2} container spacing={2}>
            <Grid item xs={6}>
              <TextField
                size="small"
                label="Flattening Pct"
                variant="outlined"
                type="number"
                value={averagingForm.flattenThresholdPct}
                onChange={(e) => handleUpdateAveragingForm('flattenThresholdPct', Number(e.target.value))}
                helperText="Sell-high trigger"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                label="Averaging Pct"
                variant="outlined"
                type="number"
                value={averagingForm.averageThresholdPct}
                onChange={(e) => handleUpdateAveragingForm('averageThresholdPct', Number(e.target.value))}
                helperText="Buy-low trigger"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    sx={{ ml: 1 }}
                    onChange={() => handleUpdateAveragingForm('averageOnLowestPriceOnly', !averagingForm.averageOnLowestPriceOnly)}
                    checked={averagingForm.averageOnLowestPriceOnly}
                  />
                }
                label={<Typography variant="body2">Average on lowest price only</Typography>}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    sx={{ ml: 1 }}
                    onChange={() => handleUpdateAveragingForm('useExponentialAveraging', !averagingForm.useExponentialAveraging)}
                    checked={averagingForm.useExponentialAveraging}
                  />
                }
                label={<Typography variant="body2">Use exponential averaging (averaging pct doubling for every avg trade)</Typography>}
              />
            </Grid>
            <Grid item xs={12}>
              <Tooltip title="Dependent on Intial Trade Size greater than 0">
                <FormControlLabel
                  control={
                    <Switch
                      sx={{ ml: 1 }}
                      onChange={() => handleUpdateAveragingForm('useExponentialFlattening', !averagingForm.useExponentialFlattening)}
                      checked={averagingForm.useExponentialFlattening}
                      disabled={averagingForm.initialSize === 0}
                    />
                  }
                  label={<Typography variant="body2">Use exponential flattening (flattening pct doubling for every exit trade)</Typography>}
                />
              </Tooltip>
            </Grid>
          </Grid>

          <Divider />
          <Typography variant="button" component="div" pt={2} pl={2}>
            Moving Average
          </Typography>
          <Grid p={2} container spacing={2} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                label="Moving Average Periods (in hours)"
                variant="outlined"
                type="number"
                value={averagingForm.movingAveragePeriods}
                onChange={(e) => handleUpdateAveragingForm('movingAveragePeriods', Number(e.target.value))}
                fullWidth
              />
              <FormHelperText>Periods are in hours with grace period</FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    sx={{ ml: 1 }}
                    onChange={() => handleUpdateAveragingForm('keepOpen', !averagingForm.keepOpen)}
                    checked={averagingForm.keepOpen}
                  />
                }
                label={<Typography variant="body2">Keep Open (position will stay open on size 0)</Typography>}
              />
            </Grid>
          </Grid>

          <Divider />
          <Box sx={{ m: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Button variant="contained" onClick={() => handleSaveBtnClick()}>
              Save
            </Button>
          </Box>
        </Box>
      </AccordionWrapper>

      <SimulatorPanel position={position} averagingForm={averagingForm} />

      {isDeveloperMode && (
        <GutterBox sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start', gap: 2 }}>
          <Button variant="text" onClick={() => handleRunOrderAutomationTaskBtnClick()}>
            Debug Order Automation Task {runTaskLoading && <CircularProgress sx={{ ml: 1 }} color="inherit" size="1rem" />}
          </Button>
        </GutterBox>
      )}
    </>
  );
};

export default AutomationPanel;
