import { FunctionComponent, useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

import {
  STRIKE_COLOR,
  AVG_COLOR,
  INDICATOR_RED_COLOR,
  SIGNAL_GREEN_COLOR,
  TRADE_BUY_COLOR,
  TRADE_SELL_COLOR
} from '../../../assets/colors';
import { CandleStickBar, Trade, TrendLine } from '../../../types/entities';
import { BarSize } from '../../../types/enums';
import { formatNum } from '../../../utils/currency-utils';

import { LineWidth, StrokeType } from './utils';

interface Props {
  bars: CandleStickBar[];
  barSize: BarSize;
  title?: string;
  strike?: number;
  limit?: number;
  stop?: number;
  avg?: number;
  onChartMouseClick?: (yAxis: number, bar: CandleStickBar) => void;
  trendLines?: TrendLine[] | undefined;
  trades?: Trade[] | undefined;
}

const BarChart: FunctionComponent<Props> = ({
  title = 'Candlestick',
  bars,
  barSize,
  strike,
  limit,
  stop,
  avg,
  onChartMouseClick,
  trendLines = [],
  trades = []
}) => {
  let currentYAxisValue = 0;
  const yAxisFormatter = (value: number) => {
    currentYAxisValue = value; // hack to get the current y-axis value at mouse click
    return `${formatNum(value)}`;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleBarClick = (event: any, chartContext: any, opts: any) => {
    const index = opts.seriesIndex !== -1 ? opts.seriesIndex : 0;
    const bar: CandleStickBar = opts.config.series[index].data[opts.dataPointIndex];
    if (opts.dataPointIndex !== -1 && onChartMouseClick) {
      onChartMouseClick(currentYAxisValue, bar);
    }
  };

  const barData = useMemo(() => {
    if (!bars || bars.length === 0) {
      return [];
    }
    // DEV-NOTE: datetimeUTC: false - meaning ApexChart is not adding/removing hours bases on localtime zone
    return bars
      .filter((bar) => !!bar.x)
      .map((bar) => ({
        x: new Date(bar.x),
        y: bar.y
      }));
  }, [bars, bars?.length]);

  const series: ApexAxisChartSeries = useMemo(() => {
    if (!barData) {
      return [];
    }
    return [
      {
        name: 'Candlestick',
        type: 'candlestick',
        data: barData
      }
    ];
  }, [barData]);

  const yaxis = useMemo(() => {
    const list: YAxisAnnotations[] = [];

    if (trendLines && trendLines.length > 0) {
      for (let i = 0; i < trendLines.length; i++) {
        const trendLine = trendLines[i];
        if (trendLine.hidden === true) {
          continue;
        }
        if (trendLine.specificOnly && trendLine.specificBarSize !== barSize) {
          continue;
        }

        list.push({
          y: trendLine.price,
          strokeDashArray: 0,
          borderColor: trendLine.color,
          label: {
            borderColor: trendLine.color,
            style: {
              color: '#000',
              background: trendLine.color
            },
            text: trendLine.name ?? 'TL'
          }
        });
      }
    }

    if (strike) {
      list.push({
        y: strike,
        borderColor: STRIKE_COLOR,
        label: {
          borderColor: STRIKE_COLOR,
          style: {
            color: '#000',
            background: STRIKE_COLOR
          },
          text: 'Strike'
        }
      });
    }

    if (avg) {
      list.push({
        y: avg,
        borderColor: AVG_COLOR,
        label: {
          borderColor: AVG_COLOR,
          style: {
            color: '#000',
            background: AVG_COLOR
          },
          text: 'Average'
        }
      });
    }

    if (limit) {
      list.push({
        y: limit,
        borderColor: SIGNAL_GREEN_COLOR,
        label: {
          borderColor: SIGNAL_GREEN_COLOR,
          style: {
            color: '#000',
            background: SIGNAL_GREEN_COLOR
          },
          text: 'Limit'
        }
      });
    }

    if (stop) {
      list.push({
        y: stop,
        borderColor: INDICATOR_RED_COLOR,
        label: {
          borderColor: INDICATOR_RED_COLOR,
          style: {
            color: '#000',
            background: INDICATOR_RED_COLOR
          },
          text: 'Stop'
        }
      });
    }

    return list;
  }, [trendLines, strike, avg, limit, stop]);

  const points = useMemo(() => {
    if (!trades || trades.length == 0) {
      return [];
    }
    let buyCount = 0;
    let sellCount = 0;
    return trades.map((trade) => {
      const isBuy = trade.action === 1;
      buyCount += isBuy ? 1 : 0;
      sellCount += !isBuy ? 1 : 0;
      const name = `${isBuy ? 'BUY' : 'SELL'}${isBuy ? buyCount : sellCount}`;
      return {
        x: new Date(trade.filled).getTime(),
        y: trade.price,
        marker: {
          size: 4,
          fillColor: isBuy ? TRADE_BUY_COLOR : TRADE_SELL_COLOR,
          strokeColor: '#fff',
          radius: 2
        },
        label: {
          borderColor: '#fff',
          offsetY: 0,
          style: {
            color: '#fff',
            background: isBuy ? TRADE_BUY_COLOR : TRADE_SELL_COLOR
          },

          text: name
        }
      } as PointAnnotations;
    });
  }, [trades]);

  const options: ApexOptions = useMemo(() => {
    return {
      theme: {
        mode: 'dark'
      },
      chart: {
        background: 'inherit',
        events: {
          click: handleBarClick
        }
      },
      title: {
        text: title,
        align: 'left'
      },
      xaxis: {
        type: 'datetime',
        labels: {
          show: true,
          datetimeUTC: false
        }
      },
      stroke: {
        width: [LineWidth.Narrow],
        dashArray: [StrokeType.Solid]
      },
      yaxis: {
        tooltip: {
          enabled: true
        },
        labels: {
          formatter: yAxisFormatter
        }
      },
      annotations: {
        points,
        yaxis
      }
    };
  }, [yaxis, points]);

  return (
    <Box>
      <Typography component="div">
        <ReactApexChart options={options} series={series} height="400" />
      </Typography>
    </Box>
  );
};

export default BarChart;
