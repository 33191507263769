import { TrendLine } from '../../types/entities';

export interface TrendLineState {
  loading: boolean;
  loaded: boolean;
  error?: string | undefined;
  trendLines: TrendLine[];
}

export const initialState: TrendLineState = {
  loading: false,
  loaded: false,
  trendLines: []
};
