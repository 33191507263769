import { FunctionComponent, useMemo, useState } from 'react';

import { Box, Dialog, DialogContent, DialogTitle, FormControlLabel, Switch, Typography } from '@mui/material';

import { GRAPH_PAGE_HOUR_CHART_ID } from '../../../assets/constants';
import { Position, Trade } from '../../../types/entities';
import { BarSize } from '../../../types/enums';
import BarChartPanel, { BarChartPanelParams } from '../../panels/BarChartPanel';
import DialogCloseIconButton from '../../ui/DialogCloseIconButton';

import './styles.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  position: Position;
  stopPrice?: number | undefined;
  limitPrice?: number | undefined;
  avgPrice?: number | number;
  trades?: Trade[] | undefined;
}

const PositionGraphDialog: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  position,
  stopPrice,
  limitPrice,
  avgPrice,
  trades = []
}: Props) => {
  const { underConId, underSecType, underSymbol } = position.details;
  const { conId, secType, symbol, localSymbol, primaryExch, exchange, currency, strike } = position.details.contract;
  const isUnderlying = secType === 'FUT' || secType === 'STK';
  const [showUnderlying, setShowUnderlying] = useState(true);

  const chartParams: BarChartPanelParams = useMemo(() => {
    return {
      barChartId: GRAPH_PAGE_HOUR_CHART_ID,
      title: '',
      symbol: !isUnderlying && showUnderlying ? underSymbol : symbol,
      conId: !isUnderlying && showUnderlying ? underConId : conId,
      secType: !isUnderlying && showUnderlying ? underSecType : secType,
      strike: isUnderlying || showUnderlying ? strike : undefined,
      avgPrice: isUnderlying || !showUnderlying ? avgPrice : undefined,
      trades: isUnderlying || !showUnderlying ? trades : undefined,
      limitPrice,
      stopPrice,
      exchange: primaryExch ?? exchange,
      currency: currency ?? 'USD',
      initialBarSize: BarSize._1_HR,
      initialDuration: 5,
      showMoreBtn: true
    };
  }, [isUnderlying, showUnderlying]);

  return (
    <Dialog open={isOpen} maxWidth="lg" onClose={onClose} classes={{ paper: 'graph-dialog__paper' }}>
      <DialogTitle sx={{ m: 0, pl: 2, pt: 2 }}>
        <Typography variant="body2">
          <b>{`${symbol} - ${localSymbol}`}</b>
        </Typography>
      </DialogTitle>
      {!isUnderlying && (
        <Box sx={{ pl: 1 }}>
          <FormControlLabel
            control={<Switch sx={{ ml: 1 }} onChange={() => setShowUnderlying(!showUnderlying)} checked={showUnderlying} />}
            label={<Typography variant="caption">Show Underlying</Typography>}
          />
        </Box>
      )}

      <DialogCloseIconButton onClose={onClose} />

      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ mt: 2 }}>
          <BarChartPanel {...chartParams} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PositionGraphDialog;
