import { FunctionComponent, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';

import { FundamentalLink, Security } from '../../../types/entities';

import NewsLinkDialog from './NewsLinkDialog';
import NewsLinkTable from './NewsLinkTable';

interface Props {
  security?: Security | undefined;
}

const NewsPanel: FunctionComponent<Props> = ({ security }: Props) => {
  const symbol = security?.parentSymbol || security?.symbol;

  const [selectedLink, setSelectedLink] = useState<FundamentalLink | undefined>();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const closeDialog = () => setShowEditDialog(false);

  const handleEditLinkBtnClick = (link: FundamentalLink | undefined) => {
    setSelectedLink(link);
    setShowEditDialog(true);
  };

  return (
    <>
      <Box sx={{ p: 2, pb: 1 }}>
        <Typography variant="h6">News Links</Typography>
      </Box>
      <NewsLinkTable security={security} onEditBtnClick={handleEditLinkBtnClick} />
      <Box sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <Button variant="outlined" size="small" color="primary" onClick={() => handleEditLinkBtnClick(undefined)}>
          Add News Link
        </Button>
      </Box>
      {showEditDialog && <NewsLinkDialog symbol={symbol} link={selectedLink} isOpen={showEditDialog} onClose={closeDialog} />}
    </>
  );
};

export default NewsPanel;
