import { FunctionComponent, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Tooltip, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { removeTrendLineAsync } from '../../../store/trend-line/service';
import { Contract, TrendLine } from '../../../types/entities';
import { useConfirm } from '../../hooks/useConfirm';

import TrendLineDialog from './TrendLineDialog';
import TrendLineTable from './TrendLineTable';
import { createTrendLineTemplate } from './utils';

interface Props {
  contract: Contract;
}

const TrendLinePanel: FunctionComponent<Props> = ({ contract }: Props) => {
  const [selectedTrendLine, setSelectedTrendLine] = useState<TrendLine | undefined>();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const closeDialog = () => setShowEditDialog(false);

  const { trendLines } = useAppSelector((gs) => gs.trendLineState);

  const dispatch = useAppDispatch();

  // Redundant: Loaded with fetchFoundationData
  // useEffect(() => {
  //   if (!loading && !loaded) {
  //     dispatch(getTrendLineAlertsAsync());
  //   }
  // }, []);

  const filteredTrendLines = useMemo(() => {
    return trendLines.filter((x) => x.conId === contract.conId);
  }, [trendLines]);

  const [confirm, ConfirmDialog] = useConfirm();
  const handleRemoveClick = async (trendLine: TrendLine) => {
    if (await confirm(`Are you sure you want to delete trade-line at $${trendLine.price.toFixed(2)}?`)) {
      dispatch(removeTrendLineAsync(trendLine));
    }
  };

  const handleOpenTrendLineDialog = (trendLine?: TrendLine | undefined) => {
    const item = trendLine ?? createTrendLineTemplate(contract);
    setSelectedTrendLine(item);
    setShowEditDialog(true);
  };

  const showTrendLinesTable = trendLines.length > 0;

  return (
    <>
      <Box sx={{ flexGrow: 1, mb: 1.5 }}>
        {!showTrendLinesTable ? (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography sx={{ px: 2 }} fontSize="smaller" component="div">
              No trend-lines exists..
            </Typography>
          </Box>
        ) : (
          <TrendLineTable trendLines={filteredTrendLines} onEdit={handleOpenTrendLineDialog} onRemove={handleRemoveClick} />
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 2 }}>
        <Tooltip title="Add Trend Line" placement="left-start">
          <Button size="small" color="primary" variant="outlined" onClick={() => handleOpenTrendLineDialog()}>
            <AddIcon sx={{ fontSize: '1.25rem' }} />
          </Button>
        </Tooltip>
      </Box>
      {selectedTrendLine && <TrendLineDialog trendLine={selectedTrendLine} isOpen={showEditDialog} onClose={closeDialog} />}
      <ConfirmDialog />
    </>
  );
};

export default TrendLinePanel;
