import { FunctionComponent, useEffect, useState } from 'react';

import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Box, Card, IconButton, Tooltip, Typography } from '@mui/material';

import FundamentalNoteDialog from '../../components/dialogs/FundamentalNoteDialog';
import FundamentalNotePanel from '../../components/panels/FundamentalNotePanel';
import NewsPanel from '../../components/panels/NewsPanel';
import QuickNotePanel from '../../components/panels/QuickNotePanel';
import Spinner from '../../components/ui/Spinner';
import { getFundamentalNotesAsync } from '../../store/fundamental-note/service';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { FundamentalNote, Security } from '../../types/entities';

interface Props {
  security: Security;
}

const FundamentalsTab: FunctionComponent<Props> = ({ security }: Props) => {
  const { loading, loaded, notes } = useAppSelector((gs) => gs.fundamentalNoteState);

  const [showEditDialog, setShowEditDialog] = useState(false);
  const [selectedNote, setSelectedNote] = useState<FundamentalNote | undefined>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!loading && !loaded) {
      dispatch(getFundamentalNotesAsync());
    }
  }, []);

  const openDialog = (note?: FundamentalNote) => {
    setSelectedNote(note);
    setShowEditDialog(true);
  };
  const closeDialog = () => setShowEditDialog(false);

  const symbol = security?.parentSymbol || security?.symbol;
  const securityNotes = notes.filter((x) => x.symbol === symbol);

  const fundamentalNotePanel =
    securityNotes.length > 0 ? (
      <FundamentalNotePanel notes={securityNotes} onOpenDialog={openDialog} />
    ) : (
      <Typography sx={{ m: 2 }} fontSize="smaller">
        No Fundamental Notes..
      </Typography>
    );

  return (
    <Box>
      <Spinner loading={loading} />

      <Card sx={{ mb: 2 }}>
        <NewsPanel security={security} />
      </Card>

      <Card sx={{ mb: 2, pb: 1.5 }}>
        <QuickNotePanel security={security} />
      </Card>

      <Card>
        <Box sx={{ p: 2, pb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6">Fundamental Notes</Typography>
          <Tooltip title="Add Fundamental Note" placement="left-start">
            <IconButton sx={{ mt: 0.5 }} size="small" color="primary" onClick={() => openDialog()}>
              <NoteAddIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
        </Box>
        {!!security ? (
          fundamentalNotePanel
        ) : (
          <Typography sx={{ m: 2 }} fontSize="smaller">
            No security selected..
          </Typography>
        )}
      </Card>
      {security && <FundamentalNoteDialog security={security} isOpen={showEditDialog} onClose={closeDialog} note={selectedNote} />}
    </Box>
  );
};

export default FundamentalsTab;
