import { FunctionComponent } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';

import { OpenOrderReport, Position, PositionPortfolio } from '../../../types/entities';

import PositionCollapsedPanel from './PositionCollapsedPanel';
import PositionExpandedPanel from './PositionExpandedPanel';
import PositionHeader from './PositionHeader';
import PositionSubHeader from './PositionSubHeader';

interface Props {
  position: Position;
  portfolio: PositionPortfolio;
  orders?: OpenOrderReport[] | undefined;
  bg: string;
  mt?: number | undefined;
}

const PositionPanel: FunctionComponent<Props> = ({ position, portfolio, orders = [], bg, mt }: Props) => {
  return (
    <Box
      sx={{
        mt,
        backgroundColor: bg,
        border: (theme) => `1px solid ${position.isBacktest ? theme.palette.secondary.main : theme.palette.grey[700]}`,
        borderRadius: '3px'
      }}
    >
      <PositionHeader position={position} portfolio={portfolio} />
      <PositionSubHeader position={position} portfolio={portfolio} />
      <Accordion disableGutters elevation={2} sx={{ backgroundColor: 'inherit' }}>
        <AccordionSummary sx={{ m: 0, p: 0, '& .MuiAccordionSummary-content': { m: 0 } }}>
          <PositionCollapsedPanel position={position} portfolio={portfolio} />
        </AccordionSummary>
        <AccordionDetails sx={{ px: 1 }}>
          <PositionExpandedPanel position={position} portfolio={portfolio} orders={orders} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default PositionPanel;
