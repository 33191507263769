import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TrendLine } from '../../types/entities';

import { initialState, TrendLineState } from './state';

const trendLineSlice = createSlice({
  name: 'trend-line',
  initialState,
  reducers: {
    reset: () => initialState,
    loading: (state: TrendLineState, action: PayloadAction<boolean>) => {
      state.error = undefined;
      state.loading = action.payload;
      state.loaded = !action.payload;
    },
    success: (state: TrendLineState, action: PayloadAction<TrendLine[]>) => {
      state.error = undefined;
      state.loading = false;
      state.loaded = true;
      state.trendLines = action.payload;
    },
    fail: (state: TrendLineState, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
      state.loaded = true;
      state.trendLines = [];
    },
    add: (state: TrendLineState, action: PayloadAction<TrendLine>) => {
      state.trendLines.push(action.payload);
      state.loading = false;
      state.loaded = true;
    },
    update: (state: TrendLineState, action: PayloadAction<TrendLine>) => {
      const dbEntry = state.trendLines.find((x) => x.id === action.payload.id);
      if (!!dbEntry) {
        const index = state.trendLines.indexOf(dbEntry);
        state.trendLines[index] = action.payload;
      }
      state.loading = false;
      state.loaded = true;
    },
    remove: (state: TrendLineState, action: PayloadAction<TrendLine>) => {
      const entry = state.trendLines.find((x) => x.id === action.payload.id);
      const index = entry ? state.trendLines.indexOf(entry) : -1;
      if (index !== -1) {
        state.trendLines.splice(index, 1);
      }
      state.loading = false;
      state.loaded = true;
    }
  }
});

const { actions, reducer } = trendLineSlice;
export const { reset, loading, success, fail, add, update, remove } = actions;
export default reducer;
