import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  HistoricalDataFailedAction,
  HistoricalDataLoadingAction,
  HistoricalDataSuccessAction,
  UpdateHistoricalDataParamsAction
} from './actions';
import { HistoricalDataState, initialHistoricalDataState, initialHistoricalDataStateByKey } from './state';
import { getKey } from './utils';

const historicalDataSlice = createSlice({
  name: 'historical-data',
  initialState: initialHistoricalDataState,
  reducers: {
    reset: () => initialHistoricalDataState,
    loading: (state: HistoricalDataState, action: PayloadAction<HistoricalDataLoadingAction>) => {
      const key = getKey({ id: action.payload.id, conId: action.payload.conId });
      const currState = state[key] || initialHistoricalDataStateByKey;
      state[key] = {
        ...currState,
        error: undefined,
        loading: action.payload.loading,
        loaded: !action.payload.loading
      };
    },
    success: (state: HistoricalDataState, action: PayloadAction<HistoricalDataSuccessAction>) => {
      const key = getKey({ id: action.payload.id, conId: action.payload.conId });
      const currState = state[key] || initialHistoricalDataStateByKey;
      state[key] = {
        ...currState,
        error: undefined,
        loading: false,
        loaded: true,
        bars: action.payload.bars
      };
    },
    fail: (state: HistoricalDataState, action: PayloadAction<HistoricalDataFailedAction>) => {
      const key = getKey({ id: action.payload.id, conId: action.payload.conId });
      const currState = state[key] || initialHistoricalDataStateByKey;
      state[key] = {
        ...currState,
        error: action.payload.error,
        loading: false,
        loaded: true,
        bars: []
      };
    },
    updateParams: (state: HistoricalDataState, action: PayloadAction<UpdateHistoricalDataParamsAction>) => {
      const key = getKey({ id: action.payload.id, conId: action.payload.conId });
      const currState = state[key] || initialHistoricalDataStateByKey;
      state[key] = {
        ...currState,
        initialized: true,
        params: action.payload.params
      };
    }
  }
});

const { actions, reducer } = historicalDataSlice;
export const { reset, loading, success, fail, updateParams } = actions;
export default reducer;
