import { FunctionComponent, useMemo } from 'react';

import { Box, Divider, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import EditPositionIconButton from '../../../../components/ui/EditPositionIconButton';
// import GotoWatchBoardIconButton from '../../../../components/ui/GotoWatchBoardIconButton';
import ShowGraphIconButton from '../../../../components/ui/ShowGraphIconButton';
import { Position, PositionPortfolio } from '../../../../types/entities';
import { calculatePositionAveragePrice } from '../../../../utils/averaging-utils';
import ShowPositionLogButton from '../../../ui/ShowPositionLogButton';
import VerticalSortIconButton from '../../../ui/VerticalSortIconButton';

import { getDetails } from './utils';

interface Props {
  position: Position;
  portfolio: PositionPortfolio;
}

const PositionHeader: FunctionComponent<Props> = ({ position, portfolio }) => {
  const { symbol, secType } = position.details.contract;
  const details = getDetails(position);

  const avgPrice = useMemo(() => {
    return calculatePositionAveragePrice(position);
  }, [position]);

  return (
    <Box
      sx={{
        px: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        gap: 2,
        backgroundColor: 'rgba(0, 0, 0, 0.2)'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Link style={{ textDecoration: 'none' }} to={`/security/${symbol}?tab=general`}>
          <Typography variant="caption" sx={{ color: (theme) => theme.palette.info.light }} component="div">
            {symbol}
          </Typography>
        </Link>
        <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
        <Typography variant="caption">{secType}</Typography>
        <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
        {details && (
          <>
            {details}
            <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
          </>
        )}

        <Typography fontSize={12} fontFamily="monospace">
          {portfolio.size > 0 ? '+' : ''}
          {portfolio.size}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <ShowGraphIconButton position={position} avgPrice={avgPrice} trades={position.trades} />
        <ShowPositionLogButton position={position} portfolio={portfolio} />
        {/* <GotoWatchBoardIconButton position={model} /> */}
        <Divider sx={{ mx: 0.5 }} orientation="vertical" flexItem />
        <VerticalSortIconButton position={position} direction="up" />
        <VerticalSortIconButton position={position} direction="down" />
        <EditPositionIconButton position={position} />
      </Box>
    </Box>
  );
};

export default PositionHeader;
