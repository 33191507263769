import { toast } from 'react-toastify';

import { AppDispatch } from '..';
import { createTrendLine, fetchTrendLinesWithAlert, removeTrendLine, updateTrendLine } from '../../api';
import { TrendLine } from '../../types/entities';
import { Logger } from '../../utils/Logger';

import { success, fail, loading, add, update, remove } from './reducer';

export const getTrendLineAlertsAsync = () => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    fetchTrendLinesWithAlert()
      .then((response) => {
        const trendLines = response.data;
        Logger.log(trendLines);
        dispatch(success(trendLines));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const createTrendLineAsync = (trendLine: TrendLine) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    createTrendLine(trendLine)
      .then((response) => {
        const tradeLineWithId = response.data;
        dispatch(add(tradeLineWithId));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const updateTrendLineAsync = (trendLine: TrendLine) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    updateTrendLine(trendLine)
      .then(() => {
        Logger.log(trendLine);
        dispatch(update(trendLine));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};

export const removeTrendLineAsync = (trendLine: TrendLine) => {
  return (dispatch: AppDispatch) => {
    dispatch(loading(true));
    removeTrendLine(trendLine)
      .then(() => {
        dispatch(remove(trendLine));
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        dispatch(fail(msg));
        toast.error(msg);
      });
  };
};
