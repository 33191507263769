import { FunctionComponent, useState } from 'react';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import TodayIcon from '@mui/icons-material/Today';
import { Box, Button, ButtonGroup } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

import { getNextFriday, getPreviousFriday } from '../../utils/backtest-utils';

interface Props {
  label: string;
  durationInDays: number;
  selectedValue: Date | undefined;
  onDateSelected: (val: Date | undefined) => void;
  clearable?: boolean;
}

const SelectDateTimeControl: FunctionComponent<Props> = ({ label, durationInDays, selectedValue, onDateSelected, clearable }) => {
  const val = selectedValue ? dayjs(selectedValue) : dayjs();
  const [value, setValue] = useState<Dayjs | undefined | null>(val);

  const changeDateHandler = (val: dayjs.Dayjs | null) => {
    setValue(val);
    const newDate = val?.toDate();
    onDateSelected(newDate);
  };

  const handlePreviousFridayBtnClick = () => {
    const dt = value ? value.toDate() : new Date();
    const prevNum = dt.setDate(dt.getDate() - durationInDays);
    const prev = getPreviousFriday(new Date(prevNum));
    const closingTimeFriday = new Date(prev.getFullYear(), prev.getMonth(), prev.getDate(), 17, 0, 0);
    setValue(dayjs(closingTimeFriday));
    onDateSelected(closingTimeFriday);
  };

  const handleNextFridayBtnClick = () => {
    const now = new Date();
    const dt = value ? value.toDate() : now;
    const nextNum = dt.setDate(dt.getDate() + durationInDays);
    const next = getNextFriday(new Date(nextNum));
    const closingTimeFriday = new Date(next.getFullYear(), next.getMonth(), next.getDate(), 17, 0, 0);
    const newDt = closingTimeFriday < now ? closingTimeFriday : now;
    setValue(dayjs(newDt));
    onDateSelected(newDt);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handlePreviousBtnClick = () => {
    const dt = value ? value.toDate() : new Date();
    const prev = dt.setDate(dt.getDate() - durationInDays);
    const prevDt = new Date(prev);
    const prevClosingTime = new Date(prevDt.getFullYear(), prevDt.getMonth(), prevDt.getDate(), 17, 0, 0);
    setValue(dayjs(prevClosingTime));
    onDateSelected(prevClosingTime);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleNextBtnClick = () => {
    const now = new Date();
    const dt = value ? value.toDate() : now;
    const next = dt.setDate(dt.getDate() + durationInDays);
    const nextDt = new Date(next);
    const nextClosingTime = new Date(nextDt.getFullYear(), nextDt.getMonth(), nextDt.getDate(), 17, 0, 0);
    const newDt = nextClosingTime < now ? nextClosingTime : now;
    setValue(dayjs(newDt));
    onDateSelected(newDt);
  };

  const handleTodayBtnClick = () => {
    const jsDate = dayjs();
    setValue(jsDate);
    onDateSelected(jsDate.toDate());
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
      <DateTimePicker
        slotProps={{ textField: { size: 'small' }, field: { clearable } }}
        sx={{ width: '100%' }}
        label={label}
        value={value}
        onChange={changeDateHandler}
      />
      <ButtonGroup>
        <Button onClick={handlePreviousFridayBtnClick}>
          <KeyboardArrowLeftIcon />
        </Button>
        <Button onClick={handleNextFridayBtnClick}>
          <KeyboardArrowRightIcon />
        </Button>
        <Button onClick={handleTodayBtnClick}>
          <TodayIcon />
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default SelectDateTimeControl;
