import { FunctionComponent, useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

import { INDICATOR_VLT_COLOR } from '../../assets/colors';
import { GENERAL_NOTE } from '../../assets/constants';
import QuickNotePanel from '../../components/panels/QuickNotePanel';
import { useAppSelector } from '../../store/hooks';
import { getQuickNoteBySymbol } from '../../store/quick-note/selectors';

const QuickNoteAccordion: FunctionComponent = () => {
  const [expanded, setExpanded] = useState(false);
  const quickNoteState = useAppSelector((gs) => gs.quickNoteState);
  const { notes } = getQuickNoteBySymbol(quickNoteState, GENERAL_NOTE);

  useEffect(() => {
    setExpanded(notes.length > 0);
  }, [notes]);

  return (
    <Accordion disableGutters={true} expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" color={notes.length > 0 ? INDICATOR_VLT_COLOR : 'inherit'}>
          Quick Notes
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <QuickNotePanel skeleton={true} />
      </AccordionDetails>
    </Accordion>
  );
};

export default QuickNoteAccordion;
