import { FunctionComponent, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button, ButtonGroup, FormControl, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material';

import { updateParams } from '../../../store/historical-data/reducer';
import { getHistoricalDataStateByKey } from '../../../store/historical-data/selectors';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { BarSize, WhatToShow } from '../../../types/enums';
import { getReduxDateString } from '../../../utils/redux-utils';
import SelectDateTimeControl from '../../form-controls/SelectDateTimeControl';
import AccordionWrapper from '../../ui/AccordionWrapper';

interface Props {
  barChartId: number;
  conId: number;
  expanded?: boolean;
  title?: string | undefined;
  sx?: object | undefined;
  children?: React.ReactNode;
}

const ChartQueryForm: FunctionComponent<Props> = ({ barChartId, conId, expanded, title = 'Presets', sx, children }: Props) => {
  const [formExpanded, setFormExpanded] = useState(expanded ?? false);
  const historicalDataState = useAppSelector((gs) => gs.historicalDataState);
  const { params } = getHistoricalDataStateByKey(barChartId, conId, historicalDataState);
  const { whatToShow, barSize, durationInDays, useRth, endDate } = params;

  const dispatch = useAppDispatch();

  const handleSelectBarSize = (bs: BarSize) => {
    const payload = {
      ...params,
      barSize: bs
    };
    dispatch(updateParams({ id: barChartId, conId, params: payload }));
  };

  const handleChangeBarDurationInDays = (days: number) => {
    const payload = {
      ...params,
      durationInDays: days
    };
    dispatch(updateParams({ id: barChartId, conId, params: payload }));
  };

  const handleSelectWhatToShow = (wts: WhatToShow) => {
    const payload = {
      ...params,
      whatToShow: wts
    };
    dispatch(updateParams({ id: barChartId, conId, params: payload }));
  };

  const handleSetEndDate = (date: Date | undefined) => {
    const endDate = getReduxDateString(date);
    const payload = {
      ...params,
      endDate
    };
    dispatch(updateParams({ id: barChartId, conId, params: payload }));
  };

  const handleToggleUseRth = (rth: boolean) => {
    const payload = {
      ...params,
      useRth: rth
    };
    dispatch(updateParams({ id: barChartId, conId, params: payload }));
  };

  return (
    <AccordionWrapper sx={sx} title={title} collapsed={!formExpanded} onChange={() => setFormExpanded(!formExpanded)}>
      <Grid sx={{ p: 1 }} item container spacing={2} xs={12} alignItems="center">
        <Grid item xs={12} md={6}>
          <ButtonGroup variant="outlined" fullWidth>
            <Button
              onClick={() => handleSelectWhatToShow(WhatToShow.TRADES)}
              variant={whatToShow === WhatToShow.TRADES ? 'contained' : 'outlined'}
            >
              Trades
            </Button>
            <Button
              onClick={() => handleSelectWhatToShow(WhatToShow.MIDPOINT)}
              variant={whatToShow === WhatToShow.MIDPOINT ? 'contained' : 'outlined'}
            >
              Mid
            </Button>
            <Button
              onClick={() => handleSelectWhatToShow(WhatToShow.ASK)}
              variant={whatToShow === WhatToShow.ASK ? 'contained' : 'outlined'}
            >
              Ask
            </Button>
            <Button
              onClick={() => handleSelectWhatToShow(WhatToShow.BID)}
              variant={whatToShow === WhatToShow.BID ? 'contained' : 'outlined'}
            >
              Bid
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12} md={6}>
          <ButtonGroup variant="outlined" fullWidth>
            <Button onClick={() => handleSelectBarSize(BarSize._15_MIN)} variant={barSize === BarSize._15_MIN ? 'contained' : 'outlined'}>
              15M
            </Button>
            <Button onClick={() => handleSelectBarSize(BarSize._1_HR)} variant={barSize === BarSize._1_HR ? 'contained' : 'outlined'}>
              HOUR
            </Button>
            <Button onClick={() => handleSelectBarSize(BarSize._1_DAY)} variant={barSize === BarSize._1_DAY ? 'contained' : 'outlined'}>
              DAY
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', justifyColumns: 'center', gap: 1 }}>
            <FormControl sx={{ width: '100%' }}>
              <TextField
                size="small"
                label="Duration In Days"
                variant="outlined"
                type="number"
                value={durationInDays}
                onChange={(e) => handleChangeBarDurationInDays(Number(e.target.value))}
              />
            </FormControl>
            <ButtonGroup>
              <Button onClick={() => handleChangeBarDurationInDays(durationInDays + 1)}>
                <AddIcon />
              </Button>
              <Button onClick={() => handleChangeBarDurationInDays(durationInDays - 1)}>
                <RemoveIcon />
              </Button>
            </ButtonGroup>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectDateTimeControl
            label="End Date"
            durationInDays={durationInDays}
            selectedValue={endDate ? new Date(endDate) : undefined}
            onDateSelected={handleSetEndDate}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ mb: 1 }}>
          <FormControlLabel
            control={<Switch sx={{ ml: 1 }} onChange={() => handleToggleUseRth(!useRth)} checked={useRth} />}
            label={<Typography variant="caption">Use Regular Trading Hours</Typography>}
          />
        </Grid>
        {children && (
          <Grid item xs={12} md={6} sx={{ mb: 1 }}>
            {children}
          </Grid>
        )}
      </Grid>
    </AccordionWrapper>
  );
};

export default ChartQueryForm;
