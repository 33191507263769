import { FunctionComponent, useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { searchContracts } from '../../api';
import DialogCloseIconButton from '../../components/ui/DialogCloseIconButton';
import Spinner from '../../components/ui/Spinner';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { createSecurityAsync } from '../../store/security/service';
import { Contract, ContractDescription, Security } from '../../types/entities';
import { SecurityCategory, SecuritySector } from '../../types/enums';
import { Logger } from '../../utils/Logger';
interface Props {
  category?: SecurityCategory | undefined;
  securities: Security[];
  isOpen: boolean;
  onClose: () => void;
}

const CURRENCY_USD = 'USD';

const SearchDialog: FunctionComponent<Props> = ({ securities, isOpen, onClose, category = SecurityCategory.Stock }: Props) => {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [secType, setSecType] = useState(category === SecurityCategory.Stock ? 'STK' : 'FUT');
  const [results, setResults] = useState<ContractDescription[]>([]);
  const [actionPressed, setActionPressed] = useState(false);

  const { loaded: secLoaded, error: secError } = useAppSelector((gs) => gs.securityState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpen) {
      setLoading(false);
      setLoaded(false);
      setResults([]);
    }
  }, [isOpen]);

  // auto-close on save successfully
  useEffect(() => {
    if (actionPressed && secLoaded && !secError) {
      onClose();
    }
  });

  const searchAsync = (query: string) => {
    setLoading(true);
    setResults([]);
    searchContracts({ query })
      .then((response) => {
        const searchResultRecords = response.data;
        Logger.log(searchResultRecords);
        setResults(searchResultRecords);
      })
      .catch((error) => {
        const msg = error.response?.data.message || error.message;
        toast.error(msg);
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  type SearchForm = { query: string };
  const formValues: Partial<SearchForm> = {
    query: ''
  };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Partial<SearchForm>>({
    values: formValues
  });

  const onSubmit: SubmitHandler<Partial<SearchForm>> = async (data) => {
    searchAsync(data.query as string);
  };

  const handleAddBtnClick = (event: React.MouseEvent<HTMLElement>, con: Contract, exchange: string, currency: string) => {
    event.stopPropagation();
    const exists = securities.find((x) => x.symbol === con.symbol);
    if (exists) {
      toast('Security symbol already exists.');
      onClose(); // ignore securities which already exists
    } else {
      const security: Security = {
        id: '',
        name: con.description || '',
        symbol: con.symbol,
        secType,
        exchange,
        multiplier: con.multiplier,
        currency,
        category: con.secType === 'STK' ? SecurityCategory.Stock : SecurityCategory.Futures,
        sector: SecuritySector.Unspecificed,
        shortList: false,
        subscribed: true,
        disabled: false
      };
      dispatch(createSecurityAsync(security));
      setActionPressed(true);
    }
  };

  const tableHeader = (
    <TableHead>
      <TableRow>
        <TableCell>Symbol</TableCell>
        <TableCell>Name</TableCell>
        <TableCell>Type</TableCell>
        <TableCell>Exchange</TableCell>
        <TableCell>Currency</TableCell>
        <TableCell align="right"></TableCell>
      </TableRow>
    </TableHead>
  );

  const filterContracts = (desc: ContractDescription) => {
    if (secType === 'STK') {
      return desc.contract.secType === secType && desc.contract.currency === CURRENCY_USD;
    } else if (secType === 'FUT') {
      const exists = desc.derivativeSecTypes?.find((x) => x === secType) !== undefined;
      return exists && (!desc.contract.currency || desc.contract.currency === CURRENCY_USD);
    }
    return false;
  };

  const tableRows = useMemo(() => {
    const filteredResults = results.filter(filterContracts);
    if (filteredResults.length === 0) {
      return undefined;
    }

    return filteredResults.map((result: ContractDescription, index) => {
      const con = result.contract;

      // fix
      let exchange = con.exchange ?? con.primaryExch;
      if (con.secType === 'STK') {
        exchange = 'SMART';
      }
      if (con.secType === 'CASH') {
        exchange = 'CME';
      }

      const currency = con.currency ?? CURRENCY_USD;

      return (
        <TableRow hover key={index}>
          <TableCell>{con.symbol}</TableCell>
          <TableCell>{con.description}</TableCell>
          <TableCell>{secType}</TableCell>
          <TableCell>{exchange}</TableCell>
          <TableCell>{currency}</TableCell>
          <TableCell align="right" sx={{ width: '50px', px: 1 }}>
            <IconButton sx={{ mr: 1 }} size="small" onClick={(e) => handleAddBtnClick(e, con, exchange, currency)} color="success">
              <AddIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
  }, [secType, results]);

  return (
    <Dialog fullWidth open={isOpen} maxWidth="md" onClose={onClose}>
      <Spinner loading={loading} />
      <DialogContent sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box>
          <Typography variant="h6">Security Search</Typography>
          <DialogCloseIconButton onClose={onClose} />
        </Box>
      </DialogContent>

      <Box
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        noValidate
        autoComplete="off"
        sx={{ px: 2, display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <Box sx={{ display: 'flex', justifiyContent: 'center', alignItems: 'flex-start', gap: 1 }}>
          <FormControl sx={{ flexGrow: 1 }}>
            <TextField
              fullWidth
              size="small"
              label="Search"
              variant="outlined"
              type="text"
              {...register('query', {
                required: 'Please fill in query',
                minLength: { value: 2, message: 'Minimum 2 characters' }
              })}
              helperText={errors?.query?.message}
              error={!!errors?.query}
            />
          </FormControl>

          <Button aria-label="close" type="submit" variant="contained" sx={{ height: '2.5rem' }}>
            <SearchIcon />
          </Button>
        </Box>

        <FormControl>
          <ButtonGroup variant="outlined" disableElevation fullWidth>
            <Button onClick={() => setSecType('STK')} variant={secType === 'STK' ? 'contained' : 'outlined'}>
              Stock
            </Button>
            <Button onClick={() => setSecType('FUT')} variant={secType !== 'STK' ? 'contained' : 'outlined'}>
              Futures
            </Button>
          </ButtonGroup>
        </FormControl>
      </Box>

      {!loaded && <Box sx={{ pb: 2 }} />}

      {loaded && tableRows && (
        <TableContainer sx={{ maxHeight: '30vh' }}>
          <Table size="small" aria-label="Security List">
            {tableHeader}
            <TableBody>{tableRows}</TableBody>
          </Table>
        </TableContainer>
      )}

      {loaded && !tableRows && (
        <Typography sx={{ p: 2 }} fontSize="smaller" component="div">
          NO HITS
        </Typography>
      )}

      <Divider />
      <DialogActions>
        <Box sx={{ display: 'flex', px: 1, gap: 1, justifyContent: 'flex-end' }}>
          <Button onClick={() => onClose()} variant="outlined">
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SearchDialog;
