import { FunctionComponent, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Tooltip, Typography } from '@mui/material';

import CustomCheckboxContol from '../../components/form-controls/CustomCheckBoxControl';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setUseFrozen, setUseSnapshot } from '../../store/user-settings/reducer';

const MarketDataSettingsPanel: FunctionComponent = () => {
  const { useFrozen, useSnapshot } = useAppSelector((gs) => gs.userSettingsState);
  const dispatch = useAppDispatch();

  const setUseFrozenHandler = () => {
    dispatch(setUseFrozen(!useFrozen));
  };

  const setUseSnapshotHandler = () => {
    dispatch(setUseSnapshot(!useSnapshot));
  };

  const [expanded, setExpanded] = useState(true);

  return (
    <Accordion sx={{ my: 2 }} expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography variant="h6">Market Data Settings</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'inherit' }}>
          <Tooltip title="Frozen-switch is depricated. Frozen result is dependant on MarketTradingHours value on server-side.">
            <CustomCheckboxContol
              label="Get Frozen Results (for closed market)"
              isChecked={useFrozen}
              setIsChecked={setUseFrozenHandler}
              small
              disabled={true}
            />
          </Tooltip>
          <CustomCheckboxContol
            label="Use Snapshots (11s) (instead of open-stream)"
            isChecked={useSnapshot}
            setIsChecked={setUseSnapshotHandler}
            small
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default MarketDataSettingsPanel;
